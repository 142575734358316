<script>
import MessagesMixins from '@/mixins/MessagesMixins';
export default {
  name: 'CommonMixins',
  mixins: [MessagesMixins],
  data() {
    return {
      optionsTable: {
        itemsPerPage: 12,
      },
      gameList: [],
      usersList: [],
      accessDenied: false,
    };
  },
  methods: {
    getGameList() {
      this.$axios
        .get('/api/v1/admin/games/?expands=usages', this.axiosGetConfig)
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              this.gameList.push({
                id: item.data.id,
                businessKey: item.data.businessKey,
                name: item.data.name,
                usedIn: item.expands.filter((it) => it.name === 'usages')[0]
                  .data,
              });
            });
          }
        })
        .catch((err) => {
          console.log('AXIOS ERROR: ', err);
        });
    },

    getLUList() {
      this.$axios
        .get('/api/v1/admin/learningunits/', this.axiosGetConfig)
        .then(() => {})
        .catch((err) => {
          this.accessDenied = true;
          console.log('AXIOS ERROR: ', err);
        });
    },

    getUsersList(val) {
      this.$axios
        .get('/api/v1/admin/users/', this.axiosGetConfig)
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              if (val) {
                if (val.some((el) => item.data.roles.includes(el))) {
                  this.usersList.push({
                    id: item.data.id,
                    name: item.data.firstName + ' ' + item.data.lastName,
                    email: item.data.email,
                    firstName: item.data.firstName,
                    lastName: item.data.lastName,
                    nickname: item.data.nickname,
                    roles: item.data.roles,
                    groups: item.data.groups,
                  });
                }
              } else {
                this.usersList.push({
                  id: item.data.id,
                  name: item.data.firstName + ' ' + item.data.lastName,
                  email: item.data.email,
                  firstName: item.data.firstName,
                  lastName: item.data.lastName,
                  nickname: item.data.nickname,
                  roles: item.data.roles,
                  groups: item.data.groups,
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log('AXIOS ERROR: ', err);
        });
    },
    uploadUsersFile(event) {
      let file = event.target.files[0];

      const formData = new FormData();
      formData.append('file', file, file.name);

      this.$axios
        .post('/api/v1/admin/users.xlsx', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.saveDialog();
        })
        .catch((err) => {
          console.error('Users file not uploaded: ', err);
        });

      this.$refs.userFileUploader.value = '';
      this.$forceUpdate();
    },
    goBackHome() {
      this.$router.push({ name: 'Home' }).catch((err) => {
        console.log(err);
      });
    },
    goBack() {
      this.$router
        .push({
          name: this.$route.params.from ? `${this.$route.params.from}` : 'Home',
        })
        .catch((err) => {
          console.log(err);
        });
    },

    capitalizeFirstLetter(string) {
      return string[0].toUpperCase() + string.slice(1).toLowerCase();
    },
  },
};
</script>
