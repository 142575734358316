<template>
  <v-container class="pa-0" fluid>
    <template v-if="showEditList">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            color="#fff"
            v-model="luSearch"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            solo
            hide-details
            class="my-4"
          />
          <v-data-table
            v-model="luSelected"
            :headers="selectedHeaders"
            :items="luList"
            :single-select="singleSelect"
            :search="luSearch"
            item-key="id"
            :value="luSelected"
            show-select
            class="elevation-1 mb-5 ak-table"
            :footer-props="{
              'items-per-page-text': 'Anzahl Ergebnisse',
              'items-per-page-options': [12, 24, 36, 48, -1],
            }"
            :options="optionsTable"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-end">
          <AKButton
            @click="cancel()"
            height="50"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
            class="ak-save mr-5"
            title="Abbrechen"
            :color="$vuetify.theme.themes.light.red"
          />
          <AKButton
            @click="saveList()"
            height="50"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
            class="ak-save"
            title="Speichern"
            :color="$vuetify.theme.themes.light.brightBlue"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row no-gutters align-content="center" justify="space-between">
        <v-col cols="12" class="d-flex justify-space-between">
          <p class="ak-colorText-white font-protext font30-600 mb-2">
            Publizieren
          </p>

          <AKButton
            icon="Pencil"
            @click="editPublicationView()"
            height="50"
            class="ak-delete-level pr-0 justify-end"
            :title="
              luSelected.length !== 0
                ? 'Lerneinheiten bearbeiten'
                : 'Lerneinheiten auswählen'
            "
            :isTransparent="true"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-form ref="form">
            <template v-if="luSelected.length === 0">
              <p class="font-roboto font16-600 ak-colorText-white">
                Noch keine Lerneinheiten publiziert.
              </p>
            </template>
            <template v-else>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-data-table
                    :headers="selectedHeadersLu"
                    :items="luSelected"
                    :footer-props="{
                      'items-per-page-text': 'Anzahl Ergebnisse',
                      'items-per-page-options': [12, 24, 36, 48, -1],
                    }"
                    :options="optionsTable"
                    item-key="id"
                    class="my-5 ak-table ak-publishing-table"
                  >
                    <template v-slot:item.from="props">
                      <AKDatetimepicker v-model="props.item.from" />
                    </template>

                    <template v-slot:item.to="props">
                      <AKDatetimepicker v-model="props.item.to" />
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="d-flex flex-column flex-md-row justify-md-end"
                >
                  <AKButton
                    @click="cancelPublication()"
                    height="50"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
                    class="ak-save mr-5 mb-5 mb-md-0"
                    title="Abbrechen"
                    :color="$vuetify.theme.themes.light.red"
                  />
                  <AKButton
                    @click="savePublication()"
                    height="50"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
                    class="ak-save"
                    title="Speichern"
                    :color="$vuetify.theme.themes.light.brightBlue"
                  />
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';
import AKDatetimepicker from '@/components/common/AKDatetimepicker';

import CommonMixins from '@/mixins/CommonMixins';
import moment from 'moment';

export default {
  name: 'PublishingView',
  components: { AKButton, AKDatetimepicker },
  mixins: [CommonMixins],
  props: {
    classId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      menu1: false,
      fromDate: '',
      toDate: '',
      dateProps: {
        noTitle: true,
        locale: 'de',
        allowedDates: this.allowedDates,
      },

      timeProps: {
        noTitle: true,
        locale: 'de',
        format: '24hr',
      },

      singleSelect: false,
      showEditList: false,
      luSearch: '',
      luSelected: [],
      luSelectedOld: [],
      luList: [],
      selectedHeaders: [
        {
          text: 'Lerneinheit',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
      selectedHeadersLu: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Von',
          align: 'start',
          sortable: false,
          value: 'from',
        },
        {
          text: 'Bis',
          align: 'start',
          sortable: false,
          value: 'to',
        },
      ],
    };
  },

  created() {
    this.getLearningUnitsForCurrentClass();
  },

  methods: {
    hasValidFromAndTo() {
      return this.$refs.form.validate();
    },

    allowedDates(val) {
      const now = new Date(Date.now());
      const yesterday = new Date(now.setDate(now.getDate()));
      return (
        new Date(val).setHours(0, 0, 0, 0) >= yesterday.setHours(0, 0, 0, 0)
      );
    },

    editPublicationView() {
      this.showEditList = true;
      this.getLearningUnits();
    },

    cancel() {
      this.showEditList = false;
      this.luSelected = this.luSelectedOld;
    },

    cancelPublication() {
      this.$emit('cancelPublication', false);
    },

    async savePublication() {
      if (!this.hasValidFromAndTo()) {
        return;
      }

      this.$store.dispatch('showOverlay');
      for (let i = 0; i < this.luSelected.length; i++) {
        try {
          let item = this.luSelected[i];
          await this.putPublication(
            item.id,
            item.from ? moment(item.from).utc().toISOString() : null,
            item.to ? moment(item.to).utc().toISOString() : null
          );
        } catch (e) {
          console.error('Unable to add publication ' + this.luSelected[i]);
        }
      }
      this.$emit('showEditList', true);
      this.$emit('cancelPublication', false);
      this.$store.dispatch('hideOverlay');
    },

    putPublication(luId, from, to) {
      const lu = [
        {
          id: luId,
          start: from,
          end: to,
        },
      ];
      return this.$axios
        .put(
          `/api/v1/admin/classes/${this.classId}/learningunits/${luId}`,
          lu,
          this.axiosPostPutConfig
        )
        .then((res) => {
          console.log('RESPONSE RECEIVED post Publication: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR post Publication: ', err);
        });
    },

    async deletePublications() {
      let difference = this.luSelectedOld.filter(
        (x) => !this.luSelected.includes(x)
      );

      if (difference.length > 0) {
        for (let i = 0; i < difference.length; i++) {
          try {
            await this.deletePublication(difference[i].id);
          } catch (e) {
            console.error('Unable to delete publication ' + difference[i]);
          }
        }
      }
    },
    deletePublication(luId) {
      return this.$axios
        .delete(`/api/v1/admin/classes/${this.classId}/learningunits/${luId}`)
        .then((res) => {
          console.log('RESPONSE RECEIVED delete Publication: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR delete Publication: ', err);
        });
    },

    saveList() {
      this.deletePublications();
      this.luSearch = '';
      this.showEditList = false;
    },

    getLearningUnitsForCurrentClass() {
      this.$axios
        .get(
          `/api/v1/admin/classes/${this.classId}/learningunits`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              this.luSelected.push({
                id: item.data.id,
                name: item.data.name,
                from: item.data.start !== null ? item.data.start : null,
                to: item.data.end !== null ? item.data.end : null,
              });
            });
          }
        });

      this.luSelectedOld = this.luSelected;
    },

    getLearningUnits() {
      this.$axios
        .get('/api/v1/admin/learningunits/', this.axiosGetConfig)
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              this.luList.push({
                id: item.data.id,
                name: item.data.name,
              });
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-table {
  overflow: scroll;
}

::v-deep .ak-table {
  .v-data-table__wrapper {
    display: inline;
  }
  th {
    .v-simple-checkbox {
      display: none;
    }
  }
}

::v-deep .ak-publishing-table {
  td {
    height: 70px !important;
    vertical-align: top !important;
  }
}
</style>
