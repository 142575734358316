<template>
  <v-form ref="form" @submit.prevent="formSubmissionPrevention">
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <v-container fluid class="ak-add-game-page pb-10">
      <v-row no-gutters class="mb-5" justify="space-between">
        <v-col cols="auto" class="pt-10 ak-side-left">
          <v-row no-gutters class="mb-5">
            <v-col cols="12">
              <AKButton
                @click="goBack"
                icon="ArrowLeft"
                :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
                :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
                class="goBackBtn"
              />
              <p class="font-roboto font16-600 ak-add-game-title my-5">
                Name Spiel
              </p>
              <v-row no-gutters justify="space-between" class="mb-4">
                <v-col cols="10" md="8" class="mb-5">
                  <v-text-field
                    clearable
                    clear-icon="mdi-close"
                    placeholder="Name Spiel"
                    solo
                    v-model="game.name"
                    width="280px"
                    :rules="notEmptyRule"
                  />
                </v-col>
                <v-col cols="10" md="8">
                  <v-text-field
                    clearable
                    clear-icon="mdi-close"
                    placeholder="Tag Spiel"
                    solo
                    v-model="game.businessKey"
                    width="280px"
                    :rules="notEmptyRule"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-8" v-if="game.id || hasGameTitle">
                <v-col cols="12">
                  <AKButton
                    icon="TrashCanOutline"
                    @click.prevent="deleteGame"
                    height="50"
                    width="200"
                    class="ak-deleteGame"
                    title="Spiel löschen"
                    :color="$vuetify.theme.themes.light.red"
                  />
                  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="ak-game-type-list"
                v-if="game.id || hasGameTitle"
              >
                <p class="font-roboto font16-600 ak-colorText-white mb-4">
                  Spieltyp wählen
                </p>
                <v-list class="pa-0" :disabled="Boolean(game.id)">
                  <v-list-item-group
                    class="flex-wrap justify-space-between"
                    v-model="selectedGameType"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, index) in gameTypeList"
                      :key="index"
                      @click="selectGameType(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.title"
                          class="font-roboto font16-400"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7" class="ak-side-right mt-10 pt-5">
          <v-row v-if="questionCounter === 0">
            <v-col cols="12" class="d-flex justify-end align-center">
              <v-btn
                text
                color="#fff"
                class="px-0 pr-2 mr-5"
                @click="addNewQuestion()"
                v-if="showAddingQuestions"
                :class="{
                  'add-question-button-disabled': !areQuestionsLoaded,
                }"
              >
                <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
                Neue Frage hinzufügen
              </v-btn>
              <AKButton
                @click="saveGame()"
                height="50"
                :width="$vuetify.breakpoint.smAndDown ? 'auto' : '200px'"
                class="ak-save"
                title="Speichern"
                :color="$vuetify.theme.themes.light.brightBlue"
                :isDisabled="
                  selectedGameType === null || selectedGameType === undefined
                "
              />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="showAddingQuestions">
            <v-col
              cols="12"
              class="ak-question"
              v-for="(item, index) in questionList"
              :key="index"
            >
              <v-row no-gutters>
                <p class="font-roboto font16-600 ak-colorText-white">
                  Introsatz
                </p>
                <v-btn
                  text
                  color="#fff"
                  @click="moveQuestionUp(index)"
                  height="20px"
                  min-width="30px"
                  class="pl-4 pr-0"
                >
                  <mdicon name="ChevronUp" size="24" />
                </v-btn>
                <v-btn
                  text
                  color="#fff"
                  @click="moveQuestionDown(index)"
                  height="20px"
                  min-width="30px"
                  class="pl-0"
                >
                  <mdicon name="ChevronDown" size="24" />
                </v-btn>
              </v-row>
              <v-text-field
                clearable
                clear-icon="mdi-close"
                placeholder="Finde die passenden Karten"
                solo
                width="100%"
                class="mb-8"
                v-model="item.name"
                :rules="notEmptyRule"
              />
              <p class="font-roboto font16-600 ak-colorText-white">
                Erklärung (optional - erscheint bei Challenge Lösung)
              </p>
              <v-text-field
                hide-details
                clearable
                clear-icon="mdi-close"
                placeholder="z.B. Eselsbrücke"
                solo
                width="100%"
                class="mb-8"
                v-model="item.description"
              />
              <template v-if="game.typeName === 'memories'">
                <AddMemoryGame
                  :questionItem="item"
                  :answerOptions="item.answerOptions"
                  :questionCounter="questionCounter"
                  :questionIndex="index"
                  @deleteQuestion="deleteQuestion"
                  @copyQuestion="copyQuestion"
                />
              </template>

              <template v-if="game.typeName === 'puzzles'">
                <AddPuzzleGame
                  :questionItem="item"
                  :answerOptions="item.answerOptions"
                  :questionCounter="questionCounter"
                  :questionIndex="index"
                  @deleteQuestion="deleteQuestion"
                  @copyQuestion="copyQuestion"
                />
              </template>

              <template v-if="game.typeName === 'pics'">
                <AddPictureGame
                  :questionItem="item"
                  :answerOptions="item.answerOptions"
                  :imageSrc="item.imageSrc"
                  :questionCounter="questionCounter"
                  :isSelecting="isSelecting"
                  :questionIndex="index"
                  @addNewAnswer="addNewAnswer"
                  @updateAnswerOptions="updateAnswerOptions"
                  @removeAnswer="removeAnswer"
                  @addQuestionImage="addQuestionImage"
                  @onFileChanged="onFileChanged"
                  @deleteQuestion="deleteQuestion"
                  @copyQuestion="copyQuestion"
                />
              </template>

              <template v-if="game.typeName === 'multichoices'">
                <AddTextGame
                  :questionItem="item"
                  :answerOptions="item.answerOptions"
                  :questionIndex="index"
                  :questionCounter="questionCounter"
                  @deleteQuestion="deleteQuestion"
                  @addNewAnswer="addNewAnswer"
                  @removeAnswer="removeAnswer"
                  @validateForm="validateForm"
                  @copyQuestion="copyQuestion"
                />
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="questionCounter !== 0">
        <v-col cols="12" class="d-flex justify-end align-center">
          <v-btn
            text
            color="#fff"
            class="px-0 pr-2 mr-5"
            @click="addNewQuestion()"
            v-if="selectedGameType !== null"
          >
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            Neue Frage hinzufügen
          </v-btn>
          <AKButton
            @click="saveGame()"
            height="50"
            :width="$vuetify.breakpoint.smAndDown ? 'auto' : '200px'"
            class="ak-save"
            title="Speichern"
            :color="$vuetify.theme.themes.light.brightBlue"
            :isDisabled="!Boolean(game.name) && !Boolean(selectedGameType)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import AddMemoryGame from '@/components/pages/games/AddGames/AddMemoryGame';
import AddPuzzleGame from '@/components/pages/games/AddGames/AddPuzzleGame';
import AddPictureGame from '@/components/pages/games/AddGames/AddPictureGame';
import AddTextGame from '@/components/pages/games/AddGames/AddTextGame';
import AKButton from '@/components/common/core/AKButton';
import CommonMixins from '@/mixins/CommonMixins';
import GamesMixins from '@/mixins/GamesMixins';
import ConfirmDialogue from '@/components/common/ConfirmDialogue';

export default {
  name: 'AddGame',
  components: {
    AKButton,
    AddMemoryGame,
    AddPictureGame,
    AddTextGame,
    AddPuzzleGame,
    ConfirmDialogue,
  },
  mixins: [CommonMixins, GamesMixins],

  data() {
    return {
      answerCounter: 0,
      questionCounter: 0,
      radioGroup: 1,
      selectedGameType: null,
      hasGameTitle: false,
      isSelecting: false,
      areQuestionsLoaded: false,
      tmpQuestionIndex: null,
      tmpQuestionImg: null,
      showAddingQuestions: false,
      gameTypeList: [
        {
          id: 0,
          title: 'Memory',
          name: 'memories',
        },
        {
          id: 1,
          title: 'Puzzle',
          name: 'puzzles',
        },
        {
          id: 2,
          title: 'Single/Multiple Choice',
          name: 'multichoices',
        },
        {
          id: 3,
          title: 'Bild (Pfeil)',
          name: 'pics',
        },
      ],
      game: {
        id: this.$route.params.gameId,
        typeId: null,
        typeName: null,
        name: null,
        link: null,
        businessKey: null,
        question: [],
      },
      questionList: [
        {
          name: null,
          introText: null,
          description: '',
          businessKey: null,
          answerOptions: [],
        },
      ],
      answerList: [],
      notEmptyRule: [(v) => (v && !!v.trim()) || 'Darf nicht leer sein'],
    };
  },
  created() {
    if (this.game.id) {
      this.getGameInformation(this.game.id);
      this.setGameInformation();
    } else {
      this.questionList = [];
    }
  },
  destroyed() {
    this.clearCurrentGameInformation();
  },
  computed: {
    hasGameInfo() {
      return this.$store.state['games'].currentGame.value.length !== 0;
    },

    gameInfo() {
      return this.hasGameInfo
        ? this.$store.state['games'].currentGame.value
        : null;
    },
  },
  methods: {
    formSubmissionPrevention() {
      // do nothing but stop the form submission
    },
    validateForm() {
      this.$refs.form.validate();
    },

    addQuestionImage(index) {
      this.tmpQuestionIndex = index;
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      let element = document.getElementById('uploader');
      if (element) {
        element.click();
      }
    },

    onFileChanged(e) {
      const img = e.target.files[0];
      const fd = new FormData();

      if (
        ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'].includes(img.type)
      ) {
        fd.append('file', img, img.name);
        fd.append('uploadedFor', 'games');
        this.questionList[this.tmpQuestionIndex].imageSrc =
          URL.createObjectURL(img);
        this.$axios
          .post('/api/cdn/content', fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log('upload image res ', res);
            this.questionList[this.tmpQuestionIndex].imageId = res.data.id;
          })
          .catch((err) => {
            console.log('image not upload: ', err);
          });

        this.tmpQuestionImg = URL.createObjectURL(img);
        this.$forceUpdate();
      } else {
        this.errorDialog('Erlaubte Dateitypen sind .png, .gif, .jp(e)g');
      }
    },

    getQuestions(link) {
      this.questionList = [];
      this.questionCounter = 0;
      this.$axios.get(`${link}/questions`, this.axiosGetConfig).then((res) => {
        if (res.data.data) {
          res.data.data.forEach((item) => {
            ++this.questionCounter;
            this.questionList.push(item.data);
          });
        }
        this.areQuestionsLoaded = true;
      });
    },

    setGameInformation() {
      if (this.gameInfo !== null) {
        this.getQuestions(this.gameInfo.link);
        this.game.name = this.gameInfo.name;
        this.game.link = this.gameInfo.link;
        this.game.businessKey =
          this.gameInfo.businessKey ||
          this.gameInfo.name.toLowerCase().split(' ').join('.');
        this.game.question = this.questionList;

        let tmpType = this.gameTypeList.filter(
          (item) => item.name === this.gameInfo.link.split('/')[5]
        )[0];

        this.game.typeName = tmpType.name;
        this.game.typeId = tmpType.id;
      }
    },

    async deleteGame() {
      if (this.game.id) {
        const yes = await this.$refs.confirmDialogue.show({
          message:
            'Wollen Sie das Spiel wirklich löschen? Das Löschen kann nicht rückgängig gemacht werden.',
        });

        if (yes) {
          await this.$axios
            .delete(`${this.game.link}`)
            .then((res) => {
              console.log('RESPONSE RECEIVED delete deleteGame: ', res);

              this.$router.push({ name: 'GamesList' }).catch((err) => {
                console.log(err);
              });
            })
            .catch((err) => {
              console.log('AXIOS ERROR delete deleteGame: ', err);
              throw err;
            });
        }
      } else {
        this.$router.push({ name: 'GamesList' }).catch((err) => {
          console.log(err);
        });
      }
    },

    async removeAnswer(index, number) {
      this.questionList[index].answerOptions = this.questionList[
        index
      ].answerOptions.filter((item, i, arr) => arr.indexOf(item) !== number);
    },

    selectGameType(item) {
      this.game.typeId = item.id;
      this.game.typeName = item.name;
    },

    async deleteQuestion(index, question) {
      const yes = await this.$refs.confirmDialogue.show({
        message:
          'Wollen Sie die Frage wirklich löschen? Das Löschen kann nicht rückgängig gemacht werden.',
      });

      if (yes) {
        if (question && question.id) {
          await this.$axios
            .delete(`${this.game.link}/questions/${question.id}`)
            .then((res) => {
              console.log('RESPONSE RECEIVED delete Question: ', res);
            })
            .catch((err) => {
              console.log('AXIOS ERROR delete Question: ', err);
              //Refresh the questions on the page
              this.setGameInformation();
              throw err;
            });
        }
        --this.questionCounter;

        this.questionList = this.questionList.filter(
          (item, i, arr) => arr.indexOf(item) !== index
        );

        this.game.question = this.game.question.filter(
          (item, i, arr) => arr.indexOf(item) !== index
        );
      }
    },

    addNewAnswer(index) {
      ++this.answerCounter;
      let tmpItem = {
        name: '',
        valid: false,
      };
      this.questionList[index].answerOptions.push(tmpItem);
      tmpItem = [];
    },

    copyQuestion(index) {
      console.log('Copy question: ' + index);
      let questionToCopy = this.questionList[index];

      let duplicatedQuestion = JSON.parse(JSON.stringify(questionToCopy));
      duplicatedQuestion.id = null;

      duplicatedQuestion.answerOptions.forEach((it) => (it.id = null));

      this.questionList.push(duplicatedQuestion);
    },

    moveQuestionUp(index) {
      if (index > 0) {
        console.log('Move');
        let element = this.questionList[index];
        this.questionList.splice(index, 1);
        this.questionList.splice(index - 1, 0, element);
      }
    },

    moveQuestionDown(index) {
      if (index < this.questionList.length - 1) {
        console.log('Move');
        let element = this.questionList[index];
        this.questionList.splice(index, 1);
        this.questionList.splice(index + 1, 0, element);
      }
    },

    updateAnswerOptions(questionIndex, answerIndex) {
      this.questionList[questionIndex].answerOptions.forEach(
        (item) => (item.valid = false)
      );
      this.questionList[questionIndex].answerOptions[
        answerIndex - 1
      ].valid = true;
    },

    addNewQuestion() {
      ++this.questionCounter;
      let tmpAnswerList = [];
      if (this.game.typeId === 0 || this.game.typeId === 1) {
        let count = this.game.typeId === 0 ? 6 : 4;
        for (let i = 0; i < count; i++) {
          tmpAnswerList.push({
            a: {
              name: '',
            },
            b: {
              name: '',
            },
          });
        }
      }
      let tmpItem = {
        name: '',
        introText: `${Math.random()}`,
        description: '',
        answerOptions: tmpAnswerList,
      };
      this.questionList.push(tmpItem);
      tmpItem = [];
    },

    async upsertQuestions(questions) {
      let questionsToPost = [];
      for (const question of questions) {
        if (question.id == null) {
          const newUpdatedQuestion = {
            answerOptions: question.answerOptions,
            description:
              question.description === null ? '' : question.description,
            id: question.id,
            name: question.name,
            imageId: question.imageId,
          };
          questionsToPost.push(newUpdatedQuestion);
        } else {
          questionsToPost.push(question);
        }
      }

      await this.$axios
        .put(
          `${this.game.link}/questions`,
          questionsToPost,
          this.axiosPostPutConfig
        )
        .then((res) => {
          this.saveDialog();
          console.log('RESPONSE RECEIVED postQuestion: ', res);
          this.setGameInformation();
        })
        .catch((err) => {
          console.log('AXIOS ERROR postQuestion: ', err);
          this.setGameInformation();
          throw err;
        });
    },

    async saveGame() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$store.dispatch('showOverlay');
      try {
        if (this.game.id) {
          const game = [
            {
              id: this.game.id,
              name: this.game.name,
              businessKey: this.game.businessKey,
            },
          ];
          if (
            this.game.name !== this.gameInfo.name ||
            this.game.businessKey !== this.gameInfo.businessKey
          ) {
            await this.$axios
              .put(`${this.game.link}`, game, this.axiosPostPutConfig)
              .then((res) => {
                this.saveDialog();
                console.log('RESPONSE RECEIVED update game: ', res);
              })
              .catch((err) => {
                console.log('AXIOS ERROR update game: ', err);
                throw err;
              });
          }
          await this.upsertQuestions(this.game.question);
        } else {
          const game = [
            {
              name: this.game.name,
              businessKey: this.game.businessKey,
            },
          ];
          await this.$axios
            .post(
              `/api/v1/admin/games/${this.game.typeName}/`,
              game,
              this.axiosPostPutConfig
            )
            .then((res) => {
              this.saveDialog();
              console.log('RESPONSE RECEIVED add new game: ', res);
              this.showAddingQuestions = true;
              this.game.id = res.data.data.id;
              this.questionCounter = 0;
              this.getGameInformation(res.data.data.id);
            })
            .catch((err) => {
              console.log('AXIOS ERROR add new game: ', err);
              throw err;
            });
        }
      } finally {
        this.$store.dispatch('hideOverlay');
      }
    },
  },
  watch: {
    gameInfo(val) {
      this.setGameInformation();
      this.selectedGameType = this.game.typeId;
      if (val.name) {
        this.showAddingQuestions = true;
      }
    },

    ['game.name'](val) {
      if (val) {
        this.hasGameTitle = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-add-game {
  &-page {
    position: relative;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      max-width: 60vw;
    }
  }
  &-title {
    color: #ffffff !important;
    margin-bottom: 13px;
  }
}

.ak-side {
  &-left {
    max-width: 280px;
  }

  &-right {
    width: calc(100% - 420px);
  }
}

::v-deep .ak-game-type-list {
  .theme--light.v-list {
    background: transparent;
    width: 100%;
  }
  .v-item-group {
    display: flex;
  }
  .v-list-item {
    background: var(--v-secondary-base);
    box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    max-width: 133px;
    height: 62px;
    padding: 0;
    text-align: center;
    margin-bottom: 11px;

    &--active {
      background: var(--v-brightBlue-base);
      color: #fff !important;

      &:before {
        opacity: 0;
      }
    }

    .v-list-item__title {
      word-break: break-word;
      white-space: normal;
    }
  }
}

.v-list--disabled {
  .v-list-item {
    background: var(--v-grey-lighten3);

    &--active {
      background: var(--v-brightBlue-base);
      color: #fff !important;

      &:before {
        opacity: 0;
      }
    }
  }
}

.add-question-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
