<template>
  <v-app id="app">
    <template v-if="$store.state.snackbar.snackStatus">
      <AKSnackbar />
    </template>

    <template v-if="$store.state.overlay.visible">
      <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>

    <template v-if="$vuetify.breakpoint.mdAndUp && !$route.meta.guidlinePage">
      <HeaderNavigation
        v-if="hasUserInfo"
        :hasUserInfo="hasUserInfo"
        :avatar="userInfo.avatar"
        :name="userInfo.name"
      />
    </template>

    <v-main :class="{ guidline: $route.meta.guidlinePage }">
      <router-view />
    </v-main>

    <AKAccessDeniedMessage v-if="!hasUserInfo" />
  </v-app>
</template>

<script>
import AKSnackbar from '@/components/common/core/AKSnackbar.vue';
import AKAccessDeniedMessage from '@/components/common/AKAccessDeniedMessage';
import HeaderNavigation from '@/components/common/HeaderNavigation';

export default {
  name: 'App',
  components: { HeaderNavigation, AKAccessDeniedMessage, AKSnackbar },

  computed: {
    hasUserInfo() {
      return this.$store.state['user'].userInformation.isLoaded;
    },
    userInfo() {
      return this.hasUserInfo
        ? this.$store.state['user'].userInformation.data
        : null;
    },
  },

  created() {
    window.scrollTo(0, 1);
  },
};
</script>

<style lang="scss" scoped>
.v-main {
  background-color: var(--v-primary-base);

  &.guidline {
    background-color: #fff;
  }
}
</style>
