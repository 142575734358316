<template>
  <div class="d-flex justify-start">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :value="toUserDateFormat"
          clearable
          outlined
          class="ak-date-input ak-date-input-date"
          placeholder="DD.MM.YYYY"
          @click:clear="clearDate"
          @blur="changeDateByTyping"
        >
          <template v-slot:append>
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-text-field
          v-model="timeValue"
          @blur="updateTime()"
          outlined
          clearable
          class="ak-date-input ak-date-input-time"
          placeholder="HH:MM"
          @click:clear="clearTime"
          :rules="validTimeRule"
          validate-on-blur
        />
      </template>
      <v-date-picker
        :allowed-dates="allowedDates"
        no-title
        v-model="pickedDate"
        @input="processDatePickerResult"
        locale="de"
        ref="picker"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AKDatetimepicker',
  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      menu: false,
      pickedDate: moment().format('YYYY-MM-DD'),
      date: null,
      timeValue: '',
      validTimeRule: [
        (v) =>
          !v || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Format HH:MM',
      ],
    };
  },

  computed: {
    toUserDateFormat() {
      return this.pickedDate
        ? moment(this.pickedDate).format(
            moment.localeData('de').longDateFormat('L')
          )
        : '';
    },
  },

  created() {
    this.processTimeInput();
  },

  methods: {
    changeDateByTyping() {
      if (this.date !== null) {
        this.pickedDate = moment(this.date, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
      this.updateTime();
    },

    clearDate() {
      this.pickedDate = moment().format('YYYY-MM-DD');
      this.date = null;
    },
    clearTime() {
      this.timeValue = '';
    },
    processDatePickerResult(val) {
      this.menu = false;
      this.pickedDate = val;
      this.date = moment(this.pickedDate).format('DD.MM.YYYY');
      this.updateTime();
    },
    allowedDates(val) {
      const now = new Date(Date.now());
      const yesterday = new Date(now.setDate(now.getDate()));
      return (
        new Date(val).setHours(0, 0, 0, 0) >= yesterday.setHours(0, 0, 0, 0)
      );
    },

    processTimeInput() {
      if (this.value !== null) {
        let localeData = moment.localeData('de');

        this.pickedDate = moment(this.value).format('YYYY-MM-DD');
        this.date = moment(this.value).format('DD.MM.YYYY');
        this.timeValue = moment(this.value).format(
          localeData.longDateFormat('LT')
        );
      }
    },

    updateTime() {
      if (this.date !== null) {
        let time = '00:00';
        if (this.timeValue !== '' && this.timeValue) {
          time = this.timeValue;
        }

        let utcDateTimestamp = moment(
          `${this.date} ${time}`,
          'DD.MM.YYYY HH:mm'
        )
          .utc()
          .toISOString();

        this.$emit('input', utcDateTimestamp);
        this.$emit('isUpdated', true);
      } else {
        this.$emit('input', null);
        this.$emit('isUpdated', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ak-date-input {
  height: 40px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 40px !important;
  }

  .v-input__append-inner {
    margin-top: auto;
    top: -3px;
    position: relative;
  }

  .v-input__slot {
    line-height: 1.2;
    height: 40px;
    padding: 5px !important;
    box-shadow: none !important;
    font-size: 12px;

    input {
      padding-left: 2px;
      font-size: 1.14em;
      color: #000;
      font-family: 'SF Pro Text', sans-serif;
    }
  }
}

.ak-date-input-date {
  width: 150px;
  max-width: 150px;
}

.ak-date-input-time {
  width: 120px;
  max-width: 120px;
}

::v-deep .vue__time-picker input.display-time {
  height: 40px;
  border-radius: 4px;
  border-color: #a1a1a1;
  font-family: 'SF Pro Text', sans-serif;

  &:hover {
    border-color: #000;
  }
}

::v-deep .vue__time-picker .controls .char {
  font-size: 30px;
  color: #757575;
}
</style>
