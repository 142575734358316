<template>
  <v-container fluid class="ak-add-user-page">
    <v-row no-gutters class="mb-5" justify="space-between">
      <v-col cols="12" class="d-flex justify-center">
        <AKButton
          @click="goBack"
          icon="ArrowLeft"
          :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
          :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
          class="goBackBtn"
        />
        <h2
          class="
            ak-colorText-white
            font-protext
            font30-600
            mt-10 mt-sm-0
            mr-5
            mb-5
          "
        >
          Userprofil hinzufügen
        </h2>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-form v-model="valid" ref="form" lazy-validation class="ak-user-form">
          <v-row no-gutters class="flex-column">
            <v-col cols="12">
              <p class="ak-colorText-white font-roboto font16-600 mb-2">
                E-mail
              </p>
              <v-text-field
                solo
                v-model="userInfo.email"
                :rules="emailRules"
                required
                color="#fff"
              />
            </v-col>

            <v-col cols="12">
              <p class="ak-colorText-white font-roboto font16-600 mb-2">
                Vorname
              </p>
              <v-text-field
                solo
                v-model="userInfo.firstname"
                :rules="firstNameRules"
                required
              />
            </v-col>

            <v-col cols="12">
              <p class="ak-colorText-white font-roboto font16-600 mb-2">
                Nachname
              </p>
              <v-text-field
                solo
                v-model="userInfo.lastname"
                :rules="lastNameRules"
                required
              />
            </v-col>

            <v-col cols="12">
              <p class="ak-colorText-white font-roboto font16-600 mb-2">
                Nickname
              </p>
              <v-text-field
                solo
                v-model="userInfo.nickname"
                :rules="nicknameRules"
                required
              />
            </v-col>

            <v-col cols="12">
              <p class="ak-colorText-white font-roboto font16-600 mb-2">
                Benutzertyp
              </p>
              <v-select
                solo
                v-model="userInfo.userType"
                :items="roleType"
                :rules="[(v) => !!v || 'Auswahl ist erforderlic']"
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-end justify-md-space-between flex-wrap"
      >
        <template v-if="userInfo.id">
          <AKButton
            icon="TrashCanOutline"
            @click="deleteUser()"
            height="50"
            :width="$vuetify.breakpoint.smAndDown ? 'auto' : '45%'"
            class="ak-deleteGame mr-5 mr-md-0"
            title="Benutzer löschen"
            :color="$vuetify.theme.themes.light.red"
          />
          <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        </template>

        <AKButton
          icon="DatabaseCheckOutline"
          @click="saveUser()"
          height="50"
          :width="$vuetify.breakpoint.smAndDown ? 'auto' : '45%'"
          class="ak-save"
          title="Speichern"
          :color="$vuetify.theme.themes.light.brightBlue"
          :isDisabled="!Boolean(formValid)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';
import CommonMixins from '@/mixins/CommonMixins';
import ConfirmDialogue from '@/components/common/ConfirmDialogue';

export default {
  name: 'AddUser',
  components: { AKButton, ConfirmDialogue },
  mixins: [CommonMixins],
  props: {},
  data() {
    return {
      roleType: ['Manager', 'Teacher', 'Student'],
      valid: false,
      userInfo: {
        id: this.$route.params.userId,
        name: null,
        firstname: '',
        lastname: '',
        email: '',
        nickname: '',
        userType: null,
      },
      firstNameRules: [
        (v) => (v && !!v.trim()) || 'Darf nicht leer sein',
        (v) =>
          v.length <= 80 || 'Der Name muss weniger als 80 Zeichen umfassen',
      ],
      lastNameRules: [
        (v) => (v && !!v.trim()) || 'Darf nicht leer sein',
        (v) =>
          v.length <= 120 ||
          'Der Nachname muss weniger als 120 Zeichen lang sein',
      ],
      nicknameRules: [
        (v) => (v && !!v.trim()) || 'Darf nicht leer sein',
        (v) =>
          v.length <= 50 ||
          'Der Nachname muss weniger als 50 Zeichen lang sein',
      ],
      emailRules: [
        (v) => (v && !!v.trim()) || 'Darf nicht leer sein',
        (v) => /.+@.+\..+/.test(v) || 'Email muss gültig sein',
        (v) =>
          v.length <= 150 || 'Der Email muss weniger als 150 Zeichen lang sein',
      ],
    };
  },

  computed: {
    formValid() {
      return (
        Boolean(this.userInfo.firstname) &&
        Boolean(this.userInfo.lastname) &&
        Boolean(this.userInfo.email) &&
        Boolean(this.userInfo.userType)
      );
    },

    nickname() {
      const firstname = this.userInfo.firstname
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        .replace(/ /g, '');
      const lastname = this.userInfo.lastname
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        .replace(/ /g, '');
      return this.userInfo.id && this.userInfo.nickname
        ? this.userInfo.nickname
        : this.userInfo.firstname.length > 0 &&
          this.userInfo.lastname.length > 0
        ? firstname + lastname
        : '';
    },

    hasUserInfo() {
      return this.$store.state['users'].currentUser.value.length !== 0;
    },
  },

  created() {
    if (this.userInfo.id) {
      this.getUserInformation(this.userInfo.id);
    }
  },

  methods: {
    getUserInformation(id) {
      this.$axios
        .get(`/api/v1/admin/users/${id}`, this.axiosGetConfig)
        .then((res) => {
          if (res.data) {
            this.userInfo.firstname = res.data.data.firstName;
            this.userInfo.lastname = res.data.data.lastName;
            this.userInfo.nickname = res.data.data.nickname;
            this.userInfo.userType = res.data.data.roles
              ? this.capitalizeFirstLetter(res.data.data.roles[0]) === 'Admin'
                ? 'Manager'
                : this.capitalizeFirstLetter(res.data.data.roles[0])
              : [];
            this.userInfo.email = res.data.data.email;
          }
        });
    },

    async deleteUser() {
      const yes = await this.$refs.confirmDialogue.show({
        message:
          'Wollen Sie den User wirklich löschen? Das Löschen kann nicht rückgängig gemacht werden.',
      });

      if (yes) {
        this.$axios
          .delete(`/api/v1/admin/users/${this.userInfo.id}`)
          .then((res) => {
            console.log('RESPONSE RECEIVED deleteUser: ', res);

            this.$router.push({ name: 'UsersList' }).catch((err) => {
              console.log(err);
            });
          })
          .catch((err) => {
            console.log('AXIOS ERROR deleteUser: ', err);
            throw err;
          });
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    async saveUser() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$store.dispatch('showOverlay');
      try {
        let tmpRoles = [];
        tmpRoles.push(this.userInfo.userType.toUpperCase());
        let user = [
          {
            firstName: this.userInfo.firstname,
            nickname: this.userInfo.nickname,
            lastName: this.userInfo.lastname,
            email: this.userInfo.email,
            roles: tmpRoles,
          },
        ];
        if (this.userInfo.id) {
          user[0].id = this.userInfo.id;
          await this.$axios
            .put(
              `/api/v1/admin/users/${this.userInfo.id}`,
              user,
              this.axiosPostPutConfig
            )
            .then((res) => {
              this.saveDialog();
              this.$router.push({ name: 'UsersList' }).catch((err) => {
                console.log(err);
              });
              console.log('RESPONSE RECEIVED update User: ', res);
            })
            .catch((err) => {
              console.log('AXIOS ERROR update User: ', err);
              throw err;
            });
        } else {
          await this.$axios
            .post('/api/v1/admin/users/', user, this.axiosPostPutConfig)
            .then((res) => {
              console.log('RESPONSE RECEIVED save User: ', res);
              if (res.data.data) {
                this.saveDialog();
                this.$router.push({ name: 'UsersList' }).catch((err) => {
                  console.log(err);
                });
                this.userInfo.id = res.data.data.id;
              }
            })
            .catch((err) => {
              console.log('AXIOS ERROR save User: ', err);
              throw err;
            });
        }
      } finally {
        this.$store.dispatch('hideOverlay');
      }
    },
  },

  watch: {
    nickname(val) {
      this.userInfo.nickname = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-add-user {
  &-page {
    position: relative;
    @media (min-width: map-get($grid-breakpoints, md)) {
      max-width: 600px;
    }
  }

  &-title {
    color: #ffffff !important;
    margin-bottom: 13px;
  }
}

.ak-user-form {
  width: 100%;
}
</style>
