<script>
export default {
  name: 'GamesMixins',
  methods: {
    clearCurrentGameInformation() {
      this.$store.dispatch('games/clearCurrentGame');
      this.$store.dispatch('games/clearQuestionData');
    },

    getGameInformation(id) {
      this.$store.dispatch('games/loadGameData', id);
    },
  },
};
</script>
