import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#273756',
        secondary: '#F1F0EC',
        third: '#728CBD',
        text: '4B4B4B',
        error: '#DB463C',
        success: '#6DB98D',
        darkBlue: '#273756',
        brightBlue: '#728CBD',
        orange: '#EB8C63',
        green: '#6DB98D',
        sandWhite: '#F1F0EC',
        grey: '#6E6E6E',
        fontGrey: '#4B4B4B',
        red: '#DB463C',
      },
    },
  },
});
