export const mutations = {
  classInformationLoaded(state, { classInformation }) {
    state.classInformation.data = {
      ...classInformation,
    };
    state.classInformation.isLoaded = true;
  },

  clearCurrentClass(state) {
    state.currentClass = {
      value: [],
    };
  },

  clearClassInformation(state) {
    state.classInformation.data = null;
    state.classInformation.isLoaded = false;
  },

  clearUnitLevel(state) {
    state.levelInformation.data = null;
    state.levelInformation.isLoaded = false;
  },

  loadCurrentClass(state, { currentClass }) {
    state.currentClass = {
      value: currentClass,
    };
    state.classInformation.isLoaded = true;
  },
};
