<template>
  <v-container
    :fluid="$vuetify.breakpoint.smAndDown"
    class="ak-games-list pb-10"
    :class="{ 'pt-10': $vuetify.breakpoint.smAndUp }"
  >
    <AKAccessDeniedMessage v-if="accessDenied" />
    <template v-else>
      <v-row no-gutters>
        <v-col cols="12" class="mt-5 d-flex justify-start align-center">
          <AKButton
            @click="goBackHome()"
            icon="ArrowLeft"
            :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
            :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
            class="goBackBtn"
          />
          <h2 class="ak-colorText-white font-protext font30-600 mr-5">
            Spiele Pool
          </h2>
          <v-btn text color="#fff" class="px-0 pr-2" @click="addNewGame()">
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            Neues Spiel
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="#fff"
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            solo
            hide-details
            class="mt-4"
          />
          <v-data-table
            :headers="headers"
            :items="gameList"
            item-key="name"
            class="elevation-0 my-5"
            :search="search"
            :footer-props="{
              'items-per-page-text': 'Anzahl Ergebnisse',
              'items-per-page-options': [12, 24, 36, 48, -1],
            }"
            :options="optionsTable"
            @click:row="rowClick"
          >
            <template v-slot:item.usedIn="{ item }">
              <span v-for="(el, index) in item.usedIn" :key="index">
                {{ el.unitName
                }}<template v-if="index < item.usedIn.length - 1">, </template>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AKAccessDeniedMessage from '@/components/common/AKAccessDeniedMessage';
import AKButton from '@/components/common/core/AKButton';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  name: 'GamesList',
  components: { AKButton, AKAccessDeniedMessage },
  mixins: [CommonMixins],
  data() {
    return {
      search: '',
    };
  },
  created() {
    this.getGameList();
  },
  computed: {
    headers() {
      return [
        { text: 'Name Spiel', value: 'name', sortable: false },
        { text: 'Schlüssel', value: 'businessKey', sortable: false },
        { text: 'Kommt vor in...', value: 'usedIn', sortable: false },
      ];
    },
  },
  methods: {
    rowClick(val) {
      this.goTo(val.id);
    },
    searchText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
      );
    },
    goTo(val) {
      if (val !== null) {
        this.$router
          .push({
            name: 'AddGame',
            params: { gameId: val, from: 'GamesList' },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addNewGame() {
      this.$router
        .push({ name: 'AddGame', params: { from: 'GamesList' } })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-games-list {
  height: 100%;
  position: relative;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 60vw;
  }
}
.ak-section-title {
  font-family: 'SFProText';
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #f1f0ec;
  margin-bottom: 25px;
  text-align: center;
}

::v-deep .v-btn__content {
  text-transform: none !important;
  letter-spacing: 0px !important;
}
</style>
