<template>
  <v-container
    :fluid="$vuetify.breakpoint.smAndDown"
    class="ak-learning-unit-list pb-10"
    :class="{ 'pt-10': $vuetify.breakpoint.smAndUp }"
  >
    <AKAccessDeniedMessage v-if="accessDenied" />
    <template v-else>
      <v-row no-gutters>
        <v-col cols="12" class="mt-5 d-flex justify-start align-center">
          <AKButton
            @click="goBackHome()"
            icon="ArrowLeft"
            :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
            :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
            class="goBackBtn"
          />
          <h2 class="ak-colorText-white font-protext font30-600 mr-5">
            Lerneinheiten
          </h2>
          <v-btn text color="#fff" class="px-0 pr-2" @click="addNewUnit()">
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            Neue Lerneinheit
          </v-btn>
        </v-col>
        <v-col cols="12">
          <template v-if="learnintUnits.length > 0">
            <v-text-field
              color="#fff"
              v-model="unitSearch"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              solo
              hide-details
              class="mt-4"
            />
            <v-data-table
              :headers="headers"
              :items="learnintUnits"
              item-key="name"
              class="elevation-0 my-5"
              :search="unitSearch"
              :footer-props="{
                'items-per-page-text': 'Anzahl Ergebnisse',
                'items-per-page-options': [12, 24, 36, 48, -1],
              }"
              :options="optionsTable"
              @click:row="rowClick"
            />
          </template>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AKAccessDeniedMessage from '@/components/common/AKAccessDeniedMessage';
import AKButton from '@/components/common/core/AKButton';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  name: 'LearningUnitList',
  components: { AKButton, AKAccessDeniedMessage },
  mixins: [CommonMixins],
  data() {
    return {
      unitSearch: '',
      learnintUnits: [],
    };
  },

  computed: {
    headers() {
      return [{ text: 'Name', value: 'name', sortable: false }];
    },
  },

  created() {
    this.$axios
      .get('/api/v1/admin/learningunits/', this.axiosGetConfig)
      .then((res) => {
        if (res.data.data) {
          res.data.data.forEach((item) => {
            this.learnintUnits.push({
              id: item.data.id,
              businessKey: item.data.businessKey,
              name: item.data.name,
            });
          });
        }
      });
  },
  methods: {
    rowClick(val) {
      this.goTo(val.id, val.name);
    },

    searchText(value, unitSearch) {
      return (
        value != null &&
        unitSearch != null &&
        typeof value === 'string' &&
        value.toString().indexOf(unitSearch) !== -1
      );
    },

    goTo(id, name) {
      if (id !== null) {
        this.$router
          .push({
            name: 'AddLearningUnit',
            params: { unitId: id, from: 'LearningUnitList', unitName: name },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addNewUnit() {
      this.$router
        .push({ name: 'AddLearningUnit', params: { from: 'LearningUnitList' } })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-learning-unit-list {
  height: 100%;
  position: relative;
}
.ak-section-title {
  font-family: 'SFProText';
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #f1f0ec;
  margin-bottom: 25px;
  text-align: center;
}

::v-deep .v-btn__content {
  text-transform: none !important;
  letter-spacing: 0px !important;
}
</style>
