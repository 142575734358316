<script>
export default {
  name: 'ClassesMixins',
  methods: {
    clearCurrentClassInformation() {
      this.$store.dispatch('classes/clearCurrentClass');
    },
  },
};
</script>
