import axios from 'axios';

export const actions = {
  async loadGameData({ commit }, gameId) {
    try {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de',
        },
      };

      const { data } = await axios.get(`/api/v1/admin/games/`, axiosConfig);

      let game = [];
      let tmp = data.data.filter((item) => item.data.id === gameId);

      game.push({
        id: tmp[0].data.id,
        name: tmp[0].data.name,
        businessKey: tmp[0].data.businessKey,
        link: tmp[0].links[0].href,
      });

      commit('loadCurrentGame', { currentGame: game[0] });
    } catch (error) {
      return;
    }
  },

  clearCurrentGame({ commit }) {
    commit('clearCurrentGame');
  },

  clearGameData({ commit }) {
    commit('clearGameInformation');
  },

  clearQuestionData({ commit }) {
    commit('clearGameQuestion');
  },
};
