<template>
  <div class="d-flex justify-center">
    <v-text-field
      v-model="timer.minutes"
      placeholder="M"
      type="number"
      solo
      class="ak-time-input"
      :rules="timerNonZeroRule"
      @blur="updateTime('minutes', timer.minutes)"
    />
    <v-text-field
      v-model="timer.seconds"
      placeholder="S"
      type="number"
      solo
      class="ak-time-input"
      :rules="timerNonZeroRule"
      @blur="updateTime('seconds', timer.seconds)"
    />
  </div>
</template>

<script>
export default {
  name: 'AKTimeInputs',
  props: {
    value: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      timer: {
        minutes: 0,
        seconds: 0,
      },
    };
  },

  created() {
    this.setTime();
  },

  computed: {
    timerNonZeroRule() {
      return [
        Number(this.timer.minutes) + Number(this.timer.seconds) !== 0 || '0!',
      ];
    },
  },

  watch: {
    value() {
      this.setTime();
    },
  },

  methods: {
    setTime() {
      this.timer.minutes = Math.floor(this.value / 60) || 0;
      this.timer.seconds = this.value - this.timer.minutes * 60 || 0;
    },

    updateTime(type, val) {
      if (type === 'minutes') {
        this.timer.minutes = (val < 0 ? 0 : val) || 0;
      }

      if (type === 'seconds') {
        this.timer.seconds = (val < 0 ? 0 : val) || 0;
      }

      let tmpTime =
        parseInt(this.timer.minutes) * 60 + parseInt(this.timer.seconds);

      this.$emit('input', tmpTime);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ak-time-input {
  width: 40px;
  max-width: 40px;
  height: 26px;
  text-align: center;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.v-text-field.v-text-field--solo .v-input__control {
    min-height: 26px !important;
  }

  .v-input__slot {
    line-height: 1.2;
    height: 26px;
    padding: 5px !important;
    box-shadow: none !important;
    border: 1px solid #a1a1a1;
    font-size: 12px;

    input {
      text-align: center;
    }
  }
}
</style>
