export const state = () => ({
  snackbar: {
    snackStatus: false,
    snackError: false,
    snackText: '',
    snackColor: '',
  },
  overlay: {
    visible: false,
  },
});
