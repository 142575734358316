<template>
  <v-form ref="form" @submit.prevent="formSubmissionPrevention">
    <v-container fluid class="ak-add-learning-unit-page">
      <v-row no-gutters class="mb-5" justify="space-between">
        <v-col cols="12" md="5" class="pt-10 ak-add-learning-unit">
          <v-row no-gutters class="mb-8">
            <v-col cols="12">
              <AKButton
                @click="goBack"
                icon="ArrowLeft"
                :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
                :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
                class="goBackBtn"
              />
              <p class="font-roboto font16-600 my-5 ak-add-learning-unit-title">
                Name Lerneinheit
              </p>
              <v-row no-gutters justify="space-between" class="mb-4">
                <v-col cols="10">
                  <v-text-field
                    clearable
                    clear-icon="mdi-close"
                    placeholder="Name"
                    solo
                    v-model="learningUnit.name"
                    class="ak-width-300"
                    :rules="notEmptyRule"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters v-if="hasUnitInfo">
                <v-col cols="12">
                  <AKButton
                    icon="TrashCanOutline"
                    @click.prevent="deleteUnit"
                    height="50"
                    width="200"
                    class="ak-deleteUnit"
                    title="Lerneinheit löschen"
                    :color="$vuetify.theme.themes.light.red"
                  />
                  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="ma-0" v-if="showAddingLevels">
            <v-col cols="12">
              <template v-for="(item, index) in levelsList">
                <div :key="index">
                  <v-switch
                    class="switch-2"
                    color="white"
                    dark
                    v-model="item.challengeOnly"
                  >
                    <template v-slot:label>
                      <span
                        v-bind:class="
                          item.challengeOnly
                            ? 'switch-label-on'
                            : 'switch-label-off'
                        "
                        >Speziallerneinheit</span
                      >
                    </template>
                  </v-switch>
                  <v-row no-gutters class="mb-8" justify="space-between">
                    <v-col cols="10" md="9" class="pa-0">
                      <p
                        class="
                          font-roboto
                          font16-600
                          ak-add-learning-unit-title ak-level-title
                        "
                      >
                        {{ item.name }}
                      </p>
                      <v-text-field
                        class="ak-width-300"
                        placeholder="Name Level"
                        solo
                        v-model="item.name"
                        :value="item.name"
                        height="50px"
                        :rules="notEmptyRule"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                      md="2"
                      class="pa-0 d-flex align-end justify-end justify-lg-start"
                      @click="
                        Boolean(item.id)
                          ? selectLevel(index)
                          : () => {
                              return true;
                            }
                      "
                    >
                      <mdicon
                        class="ak-open-level"
                        :class="{
                          isDisabled: !Boolean(item.id),
                          active: index === selectedLevelNumber,
                        }"
                        name="ChevronRight"
                        size="34"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex align-center justify-start"
                      @click="
                        Boolean(item.id)
                          ? selectChallenge(index)
                          : () => {
                              return true;
                            }
                      "
                    >
                      <p class="font-roboto font16-600 ak-colorText-white mb-0">
                        Challenge
                      </p>
                      <mdicon
                        class="ak-open-challenge"
                        :class="{
                          isDisabled: !Boolean(item.id),
                          active: index === selectedChallengeNumber,
                        }"
                        name="ChevronRight"
                        size="26"
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-col>
            <template v-if="hasUnitInfo">
              <v-col cols="12" class="px-0">
                <v-btn
                  text
                  color="#fff"
                  class="px-0 pr-2"
                  @click="addNewLevel()"
                >
                  <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
                  Level hinzufügen
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="pt-10"
          :class="{
            'pl-5 ak-side-right':
              (isChallengeSelected && challengeGames.length > 0) ||
              isLevelSelected,
          }"
        >
          <template v-if="isChallengeSelected && challengeGames.length > 0">
            <v-row
              no-gutters
              align-content="center"
              justify="space-between"
              class="mb-2"
            >
              <v-col cols="7">
                <p class="ak-colorText-white font-roboto font20-400 mb-3">
                  {{ challengeGames[0].name }}
                </p>
                <div class="d-flex justify-start align-center">
                  <p
                    class="ak-colorText-white font-roboto font20-400 mb-0 mr-5"
                  >
                    Global timer
                  </p>
                  <AKTimeInputs
                    v-if="challengeGames[0].timeAvailable >= 0"
                    v-model="challengeGames[0].timeAvailable"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <template v-if="challengeGames[0].gameConfigs === 0">
                  <p class="font-roboto font16-600 ak-colorText-white">
                    Noch keine Spiele hinzugefügt
                  </p>
                </template>
                <template v-else>
                  <v-data-table
                    :headers="challengeHeaders"
                    :items="challengeGames[0].gameConfigs"
                    v-sortable-data-table
                    @sorted="saveOrder"
                    :footer-props="{
                      'items-per-page-text': 'Anzahl Ergebnisse',
                      'items-per-page-options': [12, 24, 36, 48, -1],
                    }"
                    :options="optionsTable"
                    item-key="name"
                    class="mt-5 ak-table-challenge"
                  >
                    <template v-slot:item.actions="{ item }">
                      <div class="ak-table-td-switch">
                        <v-switch v-model="item.enabled" />
                      </div>
                    </template>

                    <template v-slot:item.points="{ item }">
                      <div class="ak-table-td">
                        <p class="ak-table-first-block mb-0"></p>
                        <div
                          class="mb-5 d-flex justify-center ak-points-block"
                          v-for="(el, index) in item.questionConfigs"
                          :key="index"
                        >
                          <v-text-field
                            v-model="el.points"
                            placeholder="Points"
                            solo
                            class="ak-points-input"
                            hide-details
                          />
                        </div>
                      </div>
                    </template>

                    <template v-slot:item.timer="{ item }">
                      <div class="ak-table-td">
                        <p class="ak-table-first-block mb-0"></p>
                        <template v-for="(el, index) in item.questionConfigs">
                          <div
                            class="mb-5 d-flex justify-center ak-points-block"
                            :key="index"
                          >
                            <AKTimeInputs
                              v-if="el.timeAvailable >= 0"
                              v-model="el.timeAvailable"
                            />
                          </div>
                        </template>
                      </div>
                    </template>

                    <template v-slot:item.name="{ item }">
                      <div class="ak-table-td">
                        <p class="font-weight-bold mb-0 ak-table-first-block">
                          {{ item.gameName }}
                        </p>
                        <p
                          class="mb-5 ak-points-block"
                          v-for="(el, index) in item.questionConfigs"
                          :key="index"
                        >
                          {{ el.questionName }}
                        </p>
                      </div>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="isLevelSelected">
            <template v-if="showEditGame">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    color="#fff"
                    v-model="gameSearch"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    solo
                    hide-details
                    class="my-4"
                  />
                  <v-data-table
                    v-model="gameSelected"
                    :headers="headers"
                    :items="gameList"
                    :single-select="singleSelect"
                    :search="gameSearch"
                    item-key="name"
                    show-select
                    class="elevation-1 mb-5 ak-table"
                    :footer-props="{
                      'items-per-page-text': 'Anzahl Ergebnisse',
                      'items-per-page-options': [12, 24, 36, 48, -1],
                    }"
                    :options="optionsTable"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-end">
                  <AKButton
                    @click="cancel()"
                    height="50"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
                    class="ak-save mr-5"
                    title="Abbrechen"
                    :color="$vuetify.theme.themes.light.red"
                  />
                  <AKButton
                    @click="saveList()"
                    height="50"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
                    class="ak-save"
                    title="Speichern"
                    :color="$vuetify.theme.themes.light.brightBlue"
                  />
                </v-col>
              </v-row>
            </template>

            <template v-else>
              <v-row
                no-gutters
                align-content="center"
                justify="space-between"
                class="mb-2"
              >
                <v-col cols="7">
                  <p class="ak-colorText-white font-roboto font20-400 mb-0">
                    {{ selectedLevelInformation[0].name }}
                  </p>
                  <p class="ak-colorText-white font-protext font30-600 mb-0">
                    Spiele hinzufügen
                  </p>
                </v-col>
                <v-col cols="5" class="d-flex justify-end">
                  <AKButton
                    icon="TrashCanOutline"
                    @click="deleteSelectedLevel(selectedLevelInformation[0])"
                    height="50"
                    width="200"
                    class="ak-delete-level pr-0 justify-start"
                    title="Level löschen"
                    :isTransparent="true"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-end">
                  <AKButton
                    icon="Pencil"
                    @click="editGameList(selectedLevelInformation[0])"
                    height="50"
                    width="200"
                    class="ak-delete-level pr-0 justify-start"
                    title="Spiele verwalten"
                    :isTransparent="true"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <template v-if="gameSelected.length === 0">
                    <p class="font-roboto font16-600 ak-colorText-white">
                      Noch keine Spiele hinzugefügt
                    </p>
                  </template>
                  <template v-else>
                    <v-data-table
                      :headers="selectedHeaders"
                      :items="showedGameForLevel"
                      v-sortable-data-table
                      @sorted="saveOrder"
                      :footer-props="{
                        'items-per-page-text': 'Anzahl Ergebnisse',
                        'items-per-page-options': [12, 24, 36, 48, -1],
                      }"
                      :options="optionsTable"
                      item-key="name"
                      class="mt-5"
                    />
                  </template>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="!showEditGame">
        <v-col cols="12" class="d-flex justify-end">
          <AKButton
            @click="saveUnit()"
            height="50"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
            class="ak-save"
            title="Speichern"
            :color="$vuetify.theme.themes.light.brightBlue"
            :isDisabled="!Boolean(learningUnit.name)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';
import AKTimeInputs from '@/components/common/AKTimeInputs';
import Sortable from 'sortablejs';
import CommonMixins from '@/mixins/CommonMixins';
import ConfirmDialogue from '@/components/common/ConfirmDialogue';

export default {
  name: 'AddNewLearningUnit',
  components: { AKButton, AKTimeInputs, ConfirmDialogue },
  mixins: [CommonMixins],
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },

  data() {
    return {
      hasUnitInfo: false,
      gameSearch: '',
      singleSelect: false,
      gameSelected: [],
      showedGameForLevel: [],
      showedGameForChallenge: [],
      headers: [
        {
          text: 'Spiel',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Tags',
          align: 'start',
          sortable: false,
          value: 'businessKey',
        },
      ],
      selectedHeaders: [
        {
          text: 'Spiel',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Tags',
          align: 'start',
          sortable: false,
          value: 'businessKey',
        },
      ],
      challengeHeaders: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Spiel',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Points',
          align: 'start',
          sortable: false,
          value: 'points',
        },
        {
          text: 'Memory Timer',
          align: 'start',
          sortable: false,
          value: 'timer',
        },
      ],
      learningUnit: {
        id: this.$route.params.unitId,
        name: null,
        businessKey: null,
        levels: [],
      },
      showEditGame: false,
      levelsList: [],
      showAddingLevels: false,
      isLevelSelected: false,
      isChallengeSelected: false,
      selectedChallengeNumber: null,
      selectedLevelNumber: null,
      levelsCounter: 0,
      isLevelHasGame: false,
      timer: {
        minutes: null,
        seconds: null,
      },
      selectedLevelInformation: null,
      challengeGames: [],
      notEmptyRule: [(v) => (v && !!v.trim()) || 'Darf nicht leer sein'],
    };
  },

  created() {
    if (this.learningUnit.id) {
      this.getCurrentUnitInformation(this.learningUnit.id);
    }
  },

  methods: {
    formSubmissionPrevention() {
      console.log('I prevent the form submission');
    },
    async getGamesForLevel() {
      this.gameSelected = [];
      this.showedGameForLevel = [];

      await this.$axios
        .get(
          `/api/v1/admin/learningunits/${this.learningUnit.id}/levels/${this.selectedLevelInformation[0].id}/games`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              let gameInfoGetLink = null;

              if (item.links) {
                gameInfoGetLink = item.links.filter(
                  (el) => el.method === 'GET' && el.rel === 'self'
                );
              }

              this.gameSelected.push({
                id: item.data.id,
                name: item.data.name,
                businessKey: item.data.businessKey,
                getLink:
                  gameInfoGetLink !== null
                    ? gameInfoGetLink.length > 0
                      ? gameInfoGetLink[0].href
                      : null
                    : null,
              });
            });
            this.isLevelHasGame = true;
          } else {
            this.isLevelHasGame = false;
          }
          this.$forceUpdate();
        });
      this.$forceUpdate();
      this.showedGameForLevel = this.gameSelected;
    },

    getGamesForChallenge() {
      this.gameSelected.map((item) => {
        this.$axios.get(item.getLink, this.axiosGetConfig).then((res) => {
          if (res.data.data) {
            this.showedGameForChallenge.push({
              id: res.data.data.id,
              name: res.data.data.name,
              questions: res.data.data.questions,
              type: res.data.links[0].href.split('/')[5],
            });
          }

          this.$forceUpdate();
        });
      });
    },

    cancel() {
      this.showEditGame = false;
    },

    searchGame(value, gameSearch) {
      return (
        value != null &&
        gameSearch != null &&
        typeof value === 'string' &&
        value.toString().indexOf(gameSearch) !== -1
      );
    },

    saveOrder(event) {
      const movedItem = this.gameSelected.splice(event.oldIndex, 1)[0];
      this.gameSelected.splice(event.newIndex, 0, movedItem);
    },

    async saveList() {
      await this.putLevel(
        this.learningUnit.id,
        this.selectedLevelInformation[0]
      );

      let tmp = [];

      for (const el of this.gameSelected) {
        tmp.push(el.id);
      }

      this.postGameToLevel(
        this.learningUnit.id,
        this.selectedLevelInformation[0].id,
        tmp
      );

      this.gameList = [];
      this.gameSearch = '';
      this.showEditGame = false;
      setTimeout(() => {
        this.getGamesForLevel();
      }, 500);
    },

    editGameList() {
      this.getGameList();
      this.showEditGame = true;
    },

    setChallengeInformation() {
      let tmpChallengeId = this.selectedLevelInformation[0].challenge;

      this.$axios
        .get(
          `/api/v1/admin/learningunits/${this.learningUnit.id}/levels/${this.selectedLevelInformation[0].id}/challenges/${tmpChallengeId}`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data) {
            this.challengeGames = [
              {
                id: tmpChallengeId,
                name: res.data.data.name,
                timeAvailable: res.data.data.timeAvailable,
                gameConfigs: res.data.data.gameConfigs,
              },
            ];

            this.timer.minutes = Math.floor(
              this.challengeGames[0].timeAvailable / 60
            );

            this.timer.seconds =
              this.challengeGames[0].timeAvailable - this.timer.minutes * 60;
            this.isChallengeSelected = true;

            this.$forceUpdate();
          }
        });
    },

    selectLevel(level) {
      this.getSelectedLevelInformation(level);
      this.selectedLevelNumber = level;
      this.showEditGame = false;
      this.isChallengeSelected = false;
      this.selectedChallengeNumber = null;
      this.gameSelected = [];
      this.gameList = [];
      this.showedGameForLevel = [];
      this.getGamesForLevel();
    },

    selectChallenge(level) {
      this.getSelectedLevelInformation(level);
      this.selectedLevelNumber = null;
      this.isLevelSelected = false;
      this.selectedChallengeNumber = level;
      this.showEditGame = false;
      this.gameSelected = [];
      this.gameList = [];
      this.showedGameForChallenge = [];
      this.getGamesForChallenge();
      this.setChallengeInformation();
    },

    async postGameToLevel(idUnit, idlevel, game) {
      await this.$axios
        .put(
          `/api/v1/admin/learningunits/${idUnit}/levels/${idlevel}/games/`,
          game,
          this.axiosPostPutConfig
        )
        .then((res) => {
          this.saveDialog();
          console.log('RESPONSE RECEIVED postGameToLevel: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR postGameToLevel: ', err);
        });
    },

    async postLevel(idUnit, level) {
      await this.$axios
        .post(
          `/api/v1/admin/learningunits/${idUnit}/levels`,
          level,
          this.axiosPostPutConfig
        )
        .then((res) => {
          this.saveDialog();
          console.log('RESPONSE RECEIVED postUpdateLevel: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR postUpdateLevel: ', err);
          throw err;
        });
    },

    async putLevel(idUnit, updatedLevel) {
      await this.$axios
        .put(
          `/api/v1/admin/learningunits/${idUnit}/levels/${updatedLevel.id}`,
          updatedLevel,
          this.axiosPostPutConfig
        )
        .then((res) => {
          this.saveDialog();
          console.log('RESPONSE RECEIVED postUpdateLevel: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR postUpdateLevel: ', err);
          throw err;
        });
    },

    getCurrentUnitInformation(idUnit) {
      this.learningUnit.levels = [];
      this.levelsList = [];
      this.$axios
        .get(
          `/api/v1/admin/learningunits/${idUnit}/?expands=*`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data.data) {
            this.learningUnit.id = res.data.data.id;
            this.learningUnit.name = res.data.data.name;
            this.hasUnitInfo = true;
          }

          if (
            res.data.expands &&
            res.data.expands[0].name === 'levels' &&
            res.data.expands[0].data &&
            res.data.expands[0].data.length > 0
          ) {
            res.data.expands[0].data.forEach((item) => {
              this.learningUnit.levels.push({
                id: item.id,
                name: item.name,
                challenge: item.challenge,
                challengeOnly: item.challengeOnly,
              });
            });
          }

          this.showAddingLevels = true;
          this.levelsList = this.learningUnit.levels;
        });
      this.$forceUpdate();
    },

    async deleteSelectedLevel(level) {
      const yes = await this.$refs.confirmDialogue.show({
        message:
          'Wollen Sie das Level wirklich löschen? Das Löschen kann nicht rückgängig gemacht werden.',
      });

      if (yes) {
        --this.levelsCounter;
        if (level.id) {
          await this.$axios
            .delete(
              `/api/v1/admin/learningunits/${this.learningUnit.id}/levels/${level.id}`
            )
            .then((res) => {
              console.log('RESPONSE RECEIVED deleteLevels: ', res);
            })
            .catch((err) => {
              console.log('AXIOS ERROR deleteLevels: ', err);
              throw err;
            });
        }
        this.levelsList = this.levelsList.filter(
          (item, i, arr) => arr.indexOf(item) !== this.selectedLevelNumber
        );

        this.learningUnit.levels = this.learningUnit.levels.filter(
          (item, i, arr) => arr.indexOf(item) !== this.selectedLevelNumber
        );

        this.selectedLevelNumber = null;
      }
    },

    addNewLevel() {
      ++this.levelsCounter;
      let tmpItem = {
        name: '',
        challengeOnly: false,
      };
      this.levelsList.push(tmpItem);
      tmpItem = [];
    },

    async deleteUnit() {
      const yes = await this.$refs.confirmDialogue.show({
        message:
          'Wollen Sie die Lerneinheit wirklich löschen? Das Löschen kann nicht rückgängig gemacht werden.',
      });
      if (yes) {
        this.$store.dispatch('showOverlay');
        this.$axios
          .delete(`/api/v1/admin/learningunits/${this.learningUnit.id}`)
          .then((res) => {
            this.$store.dispatch('hideOverlay');
            console.log('RESPONSE RECEIVED delete learning units: ', res);
            this.$router.push({ name: 'LearningUnitList' }).catch((err) => {
              console.log(err);
            });
          })
          .catch((err) => {
            this.$store.dispatch('hideOverlay');
            console.log('AXIOS ERROR delete learningunits: ', err);
            throw err;
          });
      }
    },

    saveChallenge() {
      this.$axios
        .put(
          `/api/v1/admin/learningunits/${this.learningUnit.id}/levels/${this.selectedLevelInformation[0].id}/challenges/${this.selectedLevelInformation[0].challenge}`,
          this.challengeGames[0],
          this.axiosPostPutConfig
        )
        .then((res) => {
          this.saveDialog();
          console.log('RESPONSE RECEIVED put saveChallenge: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR put saveChallenge: ', err);
        });
    },

    async saveUnit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$store.dispatch('showOverlay');
      try {
        if (this.hasUnitInfo) {
          const unit = {
            id: this.learningUnit.id,
            name: this.learningUnit.name,
            businessKey: this.learningUnit.businessKey,
          };
          if (this.learningUnit.name !== this.$route.params.unitName) {
            await this.$axios
              .put(
                `/api/v1/admin/learningunits/${this.learningUnit.id}`,
                unit,
                this.axiosPostPutConfig
              )
              .then((res) => {
                this.saveDialog();
                console.log('RESPONSE RECEIVED put learningunits: ', res);
              })
              .catch((err) => {
                console.log('AXIOS ERROR put: ', err);
                throw err;
              });
          }
        } else {
          const unit = {
            name: this.learningUnit.name,
            businessKey: this.learningUnit.businessKey,
          };
          await this.$axios
            .post('/api/v1/admin/learningunits/', unit, this.axiosPostPutConfig)
            .then((res) => {
              console.log('RESPONSE RECEIVED learningunits: ', res);
              this.saveDialog();
              this.showAddingLevels = true;
              this.learningUnit.id = res.data.data.id;
            })
            .catch((err) => {
              console.log('AXIOS ERROR: ', err);
              throw err;
            });
        }

        for await (const item of this.learningUnit.levels) {
          if (item.id) {
            await this.putLevel(this.learningUnit.id, item);
          } else {
            await this.postLevel(this.learningUnit.id, item);
          }
        }

        this.getCurrentUnitInformation(this.learningUnit.id);

        if (this.challengeGames.length > 0 && this.isChallengeSelected) {
          this.saveChallenge();
        }

        if (this.gameSelected.length > 0) {
          let tmp = [];

          for (const el of this.gameSelected) {
            tmp.push(el.id);
          }

          this.postGameToLevel(
            this.learningUnit.id,
            this.selectedLevelInformation[0].id,
            tmp
          );
        }
      } finally {
        this.$store.dispatch('hideOverlay');
      }
    },

    getSelectedLevelInformation(val) {
      this.selectedLevelInformation =
        this.levelsList.filter((item, i, arr) => arr.indexOf(item) == val) ||
        null;
    },
  },

  filters: {
    minutes(val) {
      var minutes = Math.floor(val / 60);
      return minutes;
    },
    seconds(val) {
      var minutes = Math.floor(val / 60);
      var seconds = val - minutes * 60;
      return seconds;
    },
  },

  watch: {
    selectedLevelNumber(val) {
      if (val !== null) {
        this.isLevelSelected = true;
        this.getSelectedLevelInformation(val);
      } else {
        this.isLevelSelected = false;
      }
    },

    selectedChallengeNumber(val) {
      if (val !== null) {
        this.isChallengeSelected = true;
        this.getSelectedLevelInformation(val);
      } else {
        this.isChallengeSelected = false;
      }
    },

    unitInfo(val) {
      if (val.name) {
        this.showAddingLevels = true;
      }
    },

    ['learningUnit.name'](val) {
      if (val) {
        this.learningUnit.businessKey = val.toLowerCase().split(' ').join('.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-add-learning-unit {
  &-page {
    position: relative;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      max-width: 60vw;
    }
  }
  &-title {
    color: #ffffff !important;
    margin-bottom: 13px;
  }
}
.ak-clearUnitTitle {
  border-radius: 50%;
}

.ak-open-level,
.ak-open-challenge {
  background: #ffffff;
  border-radius: 60px;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    background: var(--v-brightBlue-base);
    color: #fff;
  }

  &.isDisabled {
    opacity: 0.5;
  }
}

.ak-open-challenge {
  height: 30px;
  width: 30px;
  margin-left: 20px;
}

.ak-deleteUnit {
  ::v-deep .v-btn__content span {
    color: #fff;
  }
}

::v-deep .ak-table {
  th {
    .v-simple-checkbox {
      display: none;
    }
  }
}

.ak-level-title {
  height: 19px;
}

::v-deep .ak-time-input,
::v-deep .ak-points-input {
  width: 40px;
  max-width: 40px;
  height: 26px;
  text-align: center;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.v-text-field.v-text-field--solo .v-input__control {
    min-height: 26px !important;
  }

  .v-input__slot {
    line-height: 1.2;
    height: 26px;
    padding: 5px !important;
    box-shadow: none !important;
    border: 1px solid #a1a1a1;
    font-size: 12px;

    input {
      text-align: center;
    }
  }
}

::v-deep .ak-points-input {
  width: 50px;
  max-width: 50px;
}

.ak-table-first-block {
  height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
}

.ak-points-block {
  height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
}

::v-deep .ak-table-challenge {
  .text-start {
    vertical-align: top;
  }

  th.text-start {
    vertical-align: middle;
  }
}

.ak-table-td {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;

  &-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
}

.ak-side-right {
  border-left: 1px solid #fff;
}
::v-deep .v-input__slot {
  margin-bottom: 4px;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 4px;
}

.switch-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.switch-label-on {
  color: white;
}

.switch-label-off {
  color: darkgrey;
}
</style>
