<template>
  <v-container fluid class="ak-add-class-page">
    <v-row no-gutters class="mb-5" justify="space-between">
      <v-col cols="12" md="4" lg="3" class="pt-10 ak-add-class">
        <v-row no-gutters class="mb-5">
          <v-col cols="12">
            <AKButton
              @click="goBack"
              icon="ArrowLeft"
              :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
              :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
              class="goBackBtn"
            />
            <p class="font-roboto font16-600 my-5 ak-add-class-title">
              Klasse erstellen
            </p>
            <v-row no-gutters justify="space-between" class="mb-4">
              <v-col cols="10" md="9">
                <v-text-field
                  clearable
                  clear-icon="mdi-close"
                  placeholder="Name Klasse"
                  solo
                  v-model="learningClass.name"
                  width="100%"
                  :rules="notEmptyRule"
                />
              </v-col>
            </v-row>
            <v-row no-gutters v-if="learningClass.id">
              <v-col cols="12">
                <AKButton
                  icon="TrashCanOutline"
                  @click="deleteClass"
                  height="50"
                  width="200"
                  class="ak-deleteClass"
                  title="Klasse löschen"
                  :color="$vuetify.theme.themes.light.red"
                />
                <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" lg="9" class="pt-14 ak-side-right">
        <template v-if="showEditClass">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                color="#fff"
                v-model="userSearch"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                solo
                hide-details
                class="my-4"
              />
              <v-data-table
                v-model="userSelected"
                :headers="headers"
                :items="usersList"
                :single-select="singleSelect"
                :search="userSearch"
                :custom-filter="customSearch"
                item-key="id"
                :value="userSelected"
                show-select
                class="elevation-1 mb-5 ak-table"
                :footer-props="{
                  'items-per-page-text': 'Anzahl Ergebnisse',
                  'items-per-page-options': [12, 24, 36, 48, -1],
                }"
                :options="optionsTable"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-end">
              <AKButton
                @click="cancel()"
                height="50"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
                class="ak-save mr-5"
                title="Abbrechen"
                :color="$vuetify.theme.themes.light.red"
              />
              <AKButton
                @click="saveList()"
                height="50"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
                class="ak-save"
                title="Speichern"
                :color="$vuetify.theme.themes.light.brightBlue"
              />
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <template v-if="showPublicationView">
            <PublishingView
              :classId="learningClass.id"
              @showEditList="showEditList"
              @cancelPublication="cancelPublication"
            />
          </template>
          <template v-else>
            <v-row no-gutters align-content="center" justify="space-between">
              <v-col cols="12" class="d-flex justify-space-between mb-5">
                <p class="ak-colorText-white font-protext font30-600 mb-2">
                  User hinzufügen
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex mb-2"
                :class="
                  userSelected.length !== 0
                    ? 'justify-space-between'
                    : 'justify-end'
                "
              >
                <template v-if="userSelected.length !== 0">
                  <AKButton
                    icon="Download"
                    @click="exportData()"
                    height="50"
                    class="ak-delete-level px-0 justify-end"
                    title="Punkte exportieren"
                    :isTransparent="true"
                  />
                </template>

                <template v-if="userSelected.length !== 0">
                  <AKButton
                    icon="Plus"
                    @click="openPublicationView()"
                    height="50"
                    class="ak-delete-level px-0 justify-end"
                    title="Publizieren"
                    :isTransparent="true"
                  />
                </template>

                <AKButton
                  icon="Pencil"
                  @click="editClassList()"
                  height="50"
                  class="ak-delete-level px-0 justify-end"
                  title="Klasse verwalten"
                  :isTransparent="true"
                  v-if="!isNew"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <template v-if="userSelected.length === 0">
                  <p class="font-roboto font16-600 ak-colorText-white">
                    Noch keine User hinzugefügt
                  </p>
                </template>
                <template v-else>
                  <v-data-table
                    :headers="selectedHeaders"
                    :items="userSelected"
                    v-sortable-data-table
                    @sorted="saveOrder"
                    :footer-props="{
                      'items-per-page-text': 'Anzahl Ergebnisse',
                      'items-per-page-options': [12, 24, 36, 48, -1],
                    }"
                    :options="optionsTable"
                    item-key="name"
                    class="mt-5"
                  />
                </template>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="!showEditClass && !showPublicationView">
      <v-col cols="12" class="d-flex justify-end">
        <AKButton
          @click="saveClass()"
          height="50"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
          class="ak-save"
          title="Speichern"
          :color="$vuetify.theme.themes.light.brightBlue"
          :isDisabled="!isValid"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PublishingView from './PublishingView.vue';
import AKButton from '@/components/common/core/AKButton';
import CommonMixins from '@/mixins/CommonMixins';
import ClassesMixins from '@/mixins/ClassesMixins';
import Sortable from 'sortablejs';
import ConfirmDialogue from '@/components/common/ConfirmDialogue';

export default {
  name: 'AddClass',
  components: { AKButton, PublishingView, ConfirmDialogue },
  mixins: [CommonMixins, ClassesMixins],
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },
  data() {
    return {
      isEditListShow: false,
      showPublicationView: false,
      userSearch: '',
      singleSelect: false,
      userSelected: [],
      userSelectedOld: [],
      headers: [
        {
          text: 'User',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
        },
      ],
      selectedHeaders: [
        {
          text: 'User',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
        },
      ],
      learningClass: {
        id: this.$route.params.classId,
        name: this.$route.params.className,
        userClass: [],
      },
      showEditClass: false,
      notEmptyRule: [
        (v) => (v && !!v.trim()) || 'Darf nicht leer sein',
        (v) => (v && v.length < 80) || 'Maximal 80 Buchstaben',
      ],
    };
  },

  computed: {
    isValid() {
      return this.learningClass.name && this.learningClass.name.length < 81;
    },

    isNew() {
      return (
        this.learningClass.id === undefined || this.learningClass.id === null
      );
    },
  },

  created() {
    if (this.learningClass.id) {
      this.getClassInformation(this.learningClass.id);
      this.setClassInformation();
    }
  },

  destroyed() {
    this.clearCurrentClassInformation();
  },

  methods: {
    exportData() {
      const XLSX =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      this.$axios
        .get(`/api/v1/admin/classes/${this.learningClass.id}.xlsx`, {
          responseType: 'blob',
          headers: {
            'Accept-Language': 'de',
            Accept: XLSX,
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]), {
            type: XLSX,
          });
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', `Klasse-${this.learningClass.name}.xlsx`);
          document.body.appendChild(a);
          a.click();
          a.remove();
          this.saveDialog();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showEditList(val) {
      this.showPublicationView = val;
      this.showEditClass = !val;
      this.saveDialog();
    },

    cancel() {
      this.showEditClass = false;
      this.userSelected = this.userSelectedOld;
    },

    cancelPublication() {
      this.showPublicationView = false;
    },

    openPublicationView() {
      this.showPublicationView = true;
    },

    customSearch(value, userSearch, item) {
      if (userSearch === null) {
        return false;
      }

      let tokens = userSearch.split(' ');

      let match = true;
      for (let i = 0; i < tokens.length && match === true; i++) {
        let token = tokens[i].toString().toLowerCase();
        match =
          match &&
          (item.firstName.toString().toLowerCase().indexOf(token) !== -1 ||
            item.lastName.toString().toLowerCase().indexOf(token) !== -1 ||
            item.email.toString().toLowerCase().indexOf(token) !== -1);
      }
      return match;
    },

    searchGame(value, userSearch) {
      return (
        value != null &&
        userSearch != null &&
        typeof value === 'string' &&
        value.toString().indexOf(userSearch) !== -1
      );
    },

    saveOrder(event) {
      const movedItem = this.userSelected.splice(event.oldIndex, 1)[0];
      this.userSelected.splice(event.newIndex, 0, movedItem);
    },

    saveList() {
      this.$store.dispatch('showOverlay');
      this.deleteUsers();
      this.usersList = [];
      this.userSearch = '';
      this.showEditClass = false;
      this.$store.dispatch('hideOverlay');
    },

    async deleteUsers() {
      let difference = this.userSelectedOld.filter(
        (x) => !this.userSelected.includes(x)
      );

      if (difference.length > 0) {
        for (let i = 0; i < difference.length; i++) {
          try {
            await this.deleteUserFromClass(difference[i].id);
          } catch (e) {
            console.error('Unable to delete user ' + difference[i]);
          }
        }
      }
    },

    async deleteUserFromClass(idUser) {
      await this.$axios
        .delete(
          `/api/v1/admin/classes/${this.learningClass.id}/users/${idUser}`
        )
        .then((res) => {
          console.log('RESPONSE RECEIVED delete user from class: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR delete user from class: ', err);
          throw err;
        });
    },

    async postUserToClass(idClass, user) {
      await this.$axios
        .put(
          `/api/v1/admin/classes/${idClass}/users/${user.id}`,
          user,
          this.axiosPostPutConfig
        )
        .then((res) => {
          console.log('RESPONSE RECEIVED postUserToClass: ', res);
        })
        .catch((err) => {
          console.log('AXIOS ERROR postUserToClass: ', err);
          throw err;
        });
    },

    editClassList() {
      this.usersList = [];
      this.getUsersList();
      this.showEditClass = true;
    },

    getUserForClass() {
      this.$axios
        .get(
          `/api/v1/admin/classes/${this.learningClass.id}/users`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              this.userSelected.push({
                id: item.data.id,
                name: item.data.firstName + ' ' + item.data.lastName,
                firstName: item.data.firstName,
                lastName: item.data.lastName,
                nickname: item.data.nickname,
                email: item.data.email,
              });
            });
          }
        });

      this.userSelectedOld = this.userSelected;
    },

    setClassInformation() {
      if (this.learningClass.id) {
        this.userSelected = [];
        this.getUserForClass();
      }
    },

    async deleteClass() {
      const yes = await this.$refs.confirmDialogue.show({
        message:
          'Wollen Sie die Klasse wirklich löschen? Das Löschen kann nicht rückgängig gemacht werden.',
      });

      if (yes) {
        this.$axios
          .delete(`/api/v1/admin/classes/${this.learningClass.id}`)
          .then((res) => {
            console.log('RESPONSE RECEIVED delete Classs: ', res);
            this.$router.push({ name: 'ClassesList' }).catch((err) => {
              console.log(err);
            });
          })
          .catch((err) => {
            console.log('AXIOS ERROR delete learningClasss: ', err);
            throw err;
          });
      }
    },

    async saveClass() {
      try {
        this.$store.dispatch('showOverlay');
        if (this.learningClass.id) {
          const classItem = {
            id: this.learningClass.id,
            name: this.learningClass.name,
          };
          if (this.learningClass.name !== this.$route.params.className) {
            await this.$axios
              .put(
                `/api/v1/admin/classes/${this.learningClass.id}`,
                classItem,
                this.axiosPostPutConfig
              )
              .then((res) => {
                this.saveDialog();
                console.log('RESPONSE RECEIVED put learningClasss: ', res);
              })
              .catch((err) => {
                console.log('AXIOS ERROR put: ', err);
                throw err;
              });
          }

          let difference = this.userSelected.filter(
            (x) => !this.userSelectedOld.includes(x)
          );

          if (difference.length > 0) {
            for (let i = 0; i < difference.length; i++) {
              try {
                await this.postUserToClass(
                  this.learningClass.id,
                  difference[i]
                );
              } catch (e) {
                console.error('Unable to add user ' + difference[i]);
              }
            }
          }
          this.saveDialog();
        } else {
          const classItem = {
            name: this.learningClass.name,
          };
          await this.$axios
            .post('/api/v1/admin/classes/', classItem, this.axiosPostPutConfig)
            .then((res) => {
              this.saveDialog();
              console.log('RESPONSE RECEIVED learningClasss: ', res);
              this.learningClass.id = res.data.data.id;
              this.getClassInformation(res.data.data.id);
            })
            .catch((err) => {
              console.log('AXIOS ERROR: ', err);
              throw err;
            });
        }
      } finally {
        this.$store.dispatch('hideOverlay');
      }
    },

    getClassInformation(val) {
      this.$axios
        .get(`/api/v1/admin/classes/${val}`, this.axiosGetConfig)
        .then((res) => {
          if (res.data.data) {
            this.learningClass.name = res.data.data.name;
            this.learningClass.id = res.data.data.id;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-add-class {
  &-page {
    position: relative;
    @media (min-width: map-get($grid-breakpoints, md)) {
      max-width: 70vw;
    }
  }
  &-title {
    color: #ffffff !important;
    margin-bottom: 13px;
  }
}

.ak-clearClassTitle {
  border-radius: 50%;
}

.ak-open-level {
  background: #ffffff;
  border-radius: 60px;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;

  &.active {
    background: var(--v-brightBlue-base);
    color: #fff;
  }
}

.ak-deleteClass {
  ::v-deep .v-btn__content span {
    color: #fff;
  }
}

::v-deep .ak-table {
  th {
    .v-simple-checkbox {
      display: none;
    }
  }
}
</style>
