<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">{{ title }}</h2>
    <p>{{ message }}</p>
    <div class="btns">
      <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
      <span class="ok-btn" @click="_confirm">{{ okButton }}</span>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from '@/components/common/PopupModal';

export default {
  name: 'ConfirmDialogue',

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: 'Löschen',
    message:
      'Wollen Sie wirklich Löschen? Das Löschen kann nicht rückgängig gemacht werden.', // Main text content
    okButton: 'OK', // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: 'Abbrechen', // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      if (opts.title) {
        this.title = opts.title;
      }

      if (opts.message) {
        this.message = opts.message;
      }

      if (opts.okButton) {
        this.okButton = opts.okButton;
      }
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  padding: 0.5em 1em;
  background-color: #e89494ff;
  color: #de4e4e;
  border: 2px solid #de4e4e;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #35907f;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
