<script>
export default {
  name: 'AxiosHeaders',
  data() {
    return {
      axiosPostPutConfig: {
        headers: {
          'Content-Type': 'application/json',
          'Content-Language': 'de',
        },
      },
      axiosGetConfig: {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de',
        },
      },
      axiosConfig2: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
  },
};
</script>
