export const state = () => ({
  gameInformation: {
    data: null,
    isLoaded: false,
  },

  currentGame: {
    value: [],
  },

  questionInformation: {
    data: null,
    isLoaded: false,
  },
});
