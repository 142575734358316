export const state = () => ({
  classInformation: {
    data: null,
    isLoaded: false,
  },

  currentClass: {
    value: [],
  },
});
