<template>
  <div>
    <v-row no-gutters justify="space-between">
      <v-col cols="12">
        <p class="font-roboto font16-600 ak-colorText-white">
          Antwortmöglichkeiten
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row
          no-gutters
          justify="space-between"
          align="center"
          class="mb-3"
          v-for="(item, index) in answerOptions"
          :key="index"
        >
          <v-col cols="5">
            <v-text-field
              clearable
              clear-icon="mdi-close"
              placeholder="New answer"
              solo
              v-model="item.a.name"
              :rules="notEmptyRule"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              clearable
              clear-icon="mdi-close"
              placeholder="New answer"
              solo
              v-model="item.b.name"
              :rules="notEmptyRule"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="questionCounter !== 0"
        cols="12"
        class="d-flex justify-space-between"
      >
        <v-btn
          text
          color="#fff"
          class="px-0 pr-2"
          @click="copyQuestion(questionIndex)"
        >
          <mdicon class="ak-icon pr-3" name="ContentCopy" size="34" />
          Frage kopieren
        </v-btn>
        <AKButton
          icon="TrashCanOutline"
          @click="deleteQuestion(questionIndex, questionItem)"
          height="50"
          width="200"
          class="ak-deleteGame"
          title="Frage löschen"
          :color="$vuetify.theme.themes.light.red"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';

export default {
  name: 'AddPuzzleGame',
  components: { AKButton },
  props: {
    questionIndex: {
      type: Number,
      default: 0,
    },
    answerOptions: {
      type: Array,
      default: () => [],
    },
    questionCounter: {
      type: Number,
      default: 0,
    },
    questionItem: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      notEmptyRule: [(v) => (v && !!v.trim()) || 'Darf nicht leer sein'],
    };
  },

  methods: {
    copyQuestion(index) {
      this.$emit('copyQuestion', index);
    },
    deleteQuestion(index, item) {
      this.$emit('deleteQuestion', index, item);
    },
  },
};
</script>
