<template>
  <v-btn
    :width="width"
    :disabled="isDisabled"
    :elevation="isTransparent ? 0 : 2"
    :height="height"
    :color="color"
    :class="[
      { 'white--text': Boolean(color) || isTransparent },
      { 'is-transparent ak-colorText-sandWhite': isTransparent },
    ]"
    @click.prevent="btnClick"
    :text="isTransparent"
  >
    <template v-if="Boolean(icon)">
      <mdicon
        class="ak-icon"
        :class="{ 'mr-2': Boolean(icon) && Boolean(title) }"
        color="red"
        :name="icon"
        size="26"
      />
    </template>
    <template v-if="Boolean(title)">
      {{ title }}
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'AKButton',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },

  methods: {
    btnClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-weight: 700;
  letter-spacing: 0px !important;

  &__content {
    text-transform: none !important;
    letter-spacing: 0px !important;
  }
}

.ak-icon {
  color: #000;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: auto;
}

::v-deep .v-btn__content {
  text-transform: none !important;
}

.is-transparent {
  .v-btn__content {
    .ak-icon {
      background: #fff;
      display: flex;
      height: 44px;
      width: 44px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
