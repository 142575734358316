import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';
import { getters } from './getters';
import UserStore from './modules/user/index';
import GamesStore from './modules/games/index';
import ClassesStore from './modules/classes/index';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    user: UserStore,
    games: GamesStore,
    classes: ClassesStore,
  },
});
