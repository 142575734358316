import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';
import axios from 'axios';
import AxiosHeaders from '@/mixins/AxiosHeaders';
import MessagesMixins from '@/mixins/MessagesMixins';
import DatetimePicker from 'vuetify-datetime-picker';

Vue.config.productionTip = false;

Vue.use(DatetimePicker);

Vue.use(mdiVue, {
  icons: mdijs,
});

Vue.mixin(AxiosHeaders, MessagesMixins);
Vue.prototype.$axios = axios;

const resource = '/oauth/login/anaking';

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    if (!this.$store.state.user.userInformation.isLoaded) {
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            window.location.assign(resource);
          } else {
            let errorMessage = `Es ist Fehler ein aufgetreten. Fehlermeldung: ${error.response.data.message}`;
            if (error.response.status === 403) {
              errorMessage =
                'Sie sind nicht berechtigt diese Aktion durchzuführen';
            }
            const snackInfo = {
              snackStatus: true,
              snackError: true,
              snackColor: 'red',
              snackText: errorMessage,
            };

            this.$store.dispatch('setSnackData', snackInfo);

            return Promise.reject(error);
          }
        }
      );
      this.$store.dispatch('user/loadUserData');
    }
  },
  render: (h) => h(App),
}).$mount('#app');
