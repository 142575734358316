<template>
  <v-snackbar
    top
    v-model="snackIsOpen"
    :timeout="snackError ? -1 : 3000"
    :color="snackColor"
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <span @click="closeDialog()">
        <mdicon v-bind="attrs" class="ak-icon" name="Close" size="22" />
      </span>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AKSnackbar',

  data() {
    return {
      snackIsOpen: this.$store.state.snackbar.snackStatus,
    };
  },

  computed: {
    snackText() {
      return this.$store.state.snackbar.snackText;
    },

    snackColor() {
      return this.$store.state.snackbar.snackColor;
    },

    snackError() {
      return this.$store.state.snackbar.snackError;
    },
  },

  watch: {
    snackIsOpen(val) {
      if (!val) {
        this.$store.dispatch('clearSnackData');
      }
    },
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('clearSnackData');
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-icon {
  cursor: pointer;
}
</style>
