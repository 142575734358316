var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.showEditList)?[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"my-4",attrs:{"color":"#fff","append-icon":"mdi-magnify","label":"Suche","single-line":"","solo":"","hide-details":""},model:{value:(_vm.luSearch),callback:function ($$v) {_vm.luSearch=$$v},expression:"luSearch"}}),_c('v-data-table',{staticClass:"elevation-1 mb-5 ak-table",attrs:{"headers":_vm.selectedHeaders,"items":_vm.luList,"single-select":_vm.singleSelect,"search":_vm.luSearch,"item-key":"id","value":_vm.luSelected,"show-select":"","footer-props":{
            'items-per-page-text': 'Anzahl Ergebnisse',
            'items-per-page-options': [12, 24, 36, 48, -1],
          },"options":_vm.optionsTable},model:{value:(_vm.luSelected),callback:function ($$v) {_vm.luSelected=$$v},expression:"luSelected"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('AKButton',{staticClass:"ak-save mr-5",attrs:{"height":"50","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '200px',"title":"Abbrechen","color":_vm.$vuetify.theme.themes.light.red},on:{"click":function($event){return _vm.cancel()}}}),_c('AKButton',{staticClass:"ak-save",attrs:{"height":"50","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '200px',"title":"Speichern","color":_vm.$vuetify.theme.themes.light.brightBlue},on:{"click":function($event){return _vm.saveList()}}})],1)],1)]:[_c('v-row',{attrs:{"no-gutters":"","align-content":"center","justify":"space-between"}},[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"ak-colorText-white font-protext font30-600 mb-2"},[_vm._v(" Publizieren ")]),_c('AKButton',{staticClass:"ak-delete-level pr-0 justify-end",attrs:{"icon":"Pencil","height":"50","title":_vm.luSelected.length !== 0
              ? 'Lerneinheiten bearbeiten'
              : 'Lerneinheiten auswählen',"isTransparent":true},on:{"click":function($event){return _vm.editPublicationView()}}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form"},[(_vm.luSelected.length === 0)?[_c('p',{staticClass:"font-roboto font16-600 ak-colorText-white"},[_vm._v(" Noch keine Lerneinheiten publiziert. ")])]:[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"my-5 ak-table ak-publishing-table",attrs:{"headers":_vm.selectedHeadersLu,"items":_vm.luSelected,"footer-props":{
                    'items-per-page-text': 'Anzahl Ergebnisse',
                    'items-per-page-options': [12, 24, 36, 48, -1],
                  },"options":_vm.optionsTable,"item-key":"id"},scopedSlots:_vm._u([{key:"item.from",fn:function(props){return [_c('AKDatetimepicker',{model:{value:(props.item.from),callback:function ($$v) {_vm.$set(props.item, "from", $$v)},expression:"props.item.from"}})]}},{key:"item.to",fn:function(props){return [_c('AKDatetimepicker',{model:{value:(props.item.to),callback:function ($$v) {_vm.$set(props.item, "to", $$v)},expression:"props.item.to"}})]}}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column flex-md-row justify-md-end",attrs:{"cols":"12"}},[_c('AKButton',{staticClass:"ak-save mr-5 mb-5 mb-md-0",attrs:{"height":"50","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '200px',"title":"Abbrechen","color":_vm.$vuetify.theme.themes.light.red},on:{"click":function($event){return _vm.cancelPublication()}}}),_c('AKButton',{staticClass:"ak-save",attrs:{"height":"50","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '200px',"title":"Speichern","color":_vm.$vuetify.theme.themes.light.brightBlue},on:{"click":function($event){return _vm.savePublication()}}})],1)],1)]],2)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }