import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "Home" */ '@/components/pages/Home.vue'),
  },
  {
    path: '/users-list',
    name: 'UsersList',
    component: () =>
      import(
        /* webpackChunkName: "UsersList" */ '@/components/pages/users/UsersList.vue'
      ),
  },
  {
    path: '/games-list',
    name: 'GamesList',
    component: () =>
      import(
        /* webpackChunkName: "GamesList" */ '@/components/pages/games/GamesList.vue'
      ),
  },
  {
    path: '/classes-list',
    name: 'ClassesList',
    component: () =>
      import(
        /* webpackChunkName: "ClassesList" */ '@/components/pages/classes/ClassesList.vue'
      ),
  },
  {
    path: '/learning-unit-list',
    name: 'LearningUnitList',
    component: () =>
      import(
        /* webpackChunkName: "LearningUnitList" */
        '@/components/pages/units/LearningUnitList.vue'
      ),
  },
  {
    path: '/add-learning-unit',
    name: 'AddLearningUnit',
    component: () =>
      import(
        /* webpackChunkName: "AddLearningUnit" */ '@/components/pages/units/AddNewLearningUnit.vue'
      ),
  },
  {
    path: '/add-game',
    name: 'AddGame',
    component: () =>
      import(
        /* webpackChunkName: "AddGame" */ '@/components/pages/games/AddGame.vue'
      ),
  },
  {
    path: '/add-user',
    name: 'AddUser',
    component: () =>
      import(
        /* webpackChunkName: "AddUser" */ '@/components/pages/users/AddUser.vue'
      ),
  },
  {
    path: '/add-class',
    name: 'AddClass',
    component: () =>
      import(
        /* webpackChunkName: "AddClass" */ '@/components/pages/classes/AddClass.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
