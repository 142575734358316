export const mutations = {
  clearSnackData(state) {
    state.snackbar.snackStatus = false;
    state.snackbar.snackError = false;
    state.snackbar.snackText = '';
    state.snackbar.snackColor = '';
  },

  setSnackData(state, { snackInfo }) {
    state.snackbar = snackInfo;
  },

  showOverlay(state) {
    console.log('Show overlay ' + Date.now());
    state.overlay.visible = true;
  },

  hideOverlay(state) {
    console.log('Hide overlay ' + Date.now());
    state.overlay.visible = false;
  },
};
