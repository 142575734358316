<script>
export default {
  name: 'MessagesMixins',
  methods: {
    saveDialog() {
      const snackInfo = {
        snackStatus: true,
        snackError: false,
        snackColor: 'success',
        snackText: 'Aktion wurde erfolgreich ausgeführt',
      };

      this.$store.dispatch('setSnackData', snackInfo);
    },

    errorDialog(val) {
      const snackInfo = {
        snackStatus: true,
        snackError: true,
        snackColor: 'red',
        snackText: val ? val : 'Ein Fehler ist aufgetreten!',
      };

      this.$store.dispatch('setSnackData', snackInfo);
    },

    deleteDialog() {
      const snackInfo = {
        snackStatus: true,
        snackError: false,
        snackColor: 'info',
        snackText: 'Aktion wurde erfolgreich ausgeführt',
      };

      this.$store.dispatch('setSnackData', snackInfo);
    },

    cancelDialog() {
      const snackInfo = {
        snackStatus: true,
        snackColor: 'info',
        snackText: 'Abgebrochen',
      };

      this.$store.dispatch('setSnackData', snackInfo);
    },
  },
};
</script>
