import axios from 'axios';

export const actions = {
  async loadClassData({ commit }, unitId) {
    try {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de',
        },
      };

      const { data } = await axios.get(
        `/api/v1/admin/classes/?expands=*`,
        axiosConfig
      );

      let classes = [];

      data.data.forEach((item) => {
        classes.push({
          id: item.data.id,
          name: item.data.name,
        });
      });

      let currentClass = classes.filter((item) => item.id === unitId);

      commit('loadCurrentClass', { currentClass: currentClass[0] });
    } catch (error) {
      return;
    }
  },

  clearCurrentClass({ commit }) {
    commit('clearCurrentClass');
  },

  clearClassData({ commit }) {
    commit('clearClassInformation');
  },
};
