import axios from 'axios';

export const actions = {
  async loadUserData({ commit }) {
    try {
      const { data } = await axios.get('/api/v1/user');
      commit('userInformationLoaded', { userInformation: data });
    } catch (error) {
      return;
    }
  },
};
