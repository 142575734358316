<template>
  <div>
    <v-row no-gutters justify="space-between">
      <v-col cols="10">
        <p class="font-roboto font16-600 ak-colorText-white">
          Antwortmöglichkeiten
        </p>
      </v-col>
      <v-col cols="2">
        <p class="font-roboto font16-600 ak-colorText-white">
          Korrekte Antwort
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="4" class="mb-6">
        <template v-if="imageSrc">
          <v-img
            height="170"
            width="164"
            contain
            :src="imageSrc"
            class="mb-4"
          />
        </template>

        <v-btn
          text
          color="#fff"
          max-width="164"
          class="px-0"
          @click="addQuestionImage(questionIndex)"
          :loading="isSelecting"
        >
          <mdicon class="ak-icon pr-2" name="PlusCircle" size="34" />
          Bild hochladen/<br />ändern
        </v-btn>
        <input
          id="uploader"
          class="d-none"
          type="file"
          accept="image/*"
          @change="onFileChanged"
        />
      </v-col>
      <v-col cols="8" class="pl-5">
        <v-row no-gutters justify="space-between">
          <v-col cols="9">
            <v-row
              no-gutters
              justify="space-between"
              align="center"
              class="mb-3 flex-row"
              v-for="(item, answerOptionIndex) in answerOptions"
              :key="answerOptionIndex"
            >
              <v-col cols="9">
                <v-text-field
                  clearable
                  clear-icon="mdi-close"
                  placeholder="New answer"
                  solo
                  v-model="item.name"
                  :rules="notEmptyRule"
                />
              </v-col>
              <v-col cols="1">
                <v-btn
                  text
                  color="#fff"
                  class="px-0 pr-2"
                  @click="removeAnswer(questionIndex, answerOptionIndex)"
                >
                  <mdicon
                    class="ak-icon pr-3"
                    name="TrashCanOutline"
                    size="34"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-radio-group
              v-model="selectedAnswer"
              :rules="oneOptionSelectedRule"
              class="ma-0 ak-question-radio-wrapper"
            >
              <v-radio
                v-for="n in answerOptions.length"
                :key="n"
                class="ak-question-radio mb-4 pa-0"
                hide-details
                :color="$vuetify.theme.themes.light.brightBlue"
                :value="n"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="questionCounter !== 0"
        cols="12"
        class="d-flex justify-space-between"
      >
        <v-btn
          text
          color="#fff"
          class="px-0 pr-2"
          @click="addNewAnswer(questionIndex)"
        >
          <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
          Antwort hinzufügen
        </v-btn>
        <v-btn
          text
          color="#fff"
          class="px-0 pr-2"
          @click="copyQuestion(questionIndex)"
        >
          <mdicon class="ak-icon pr-3" name="ContentCopy" size="34" />
          Frage kopieren
        </v-btn>
        <AKButton
          icon="TrashCanOutline"
          @click="deleteQuestion(questionIndex, questionItem)"
          height="50"
          width="200"
          class="ak-deleteGame"
          title="Frage löschen"
          :color="$vuetify.theme.themes.light.red"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';

export default {
  name: 'AddPictureGame',
  components: { AKButton },
  props: {
    questionItem: {
      type: Object,
      default: () => {},
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
    answerOptions: {
      type: Array,
      default: () => [],
    },
    questionCounter: {
      type: Number,
      default: 0,
    },
    imageSrc: {
      type: String,
      default: null,
    },
    isSelecting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const isValid = (el) => el.valid === true;
    return {
      selectedAnswer:
        this.answerOptions.length > 0
          ? this.answerOptions.findIndex(isValid) + 1
          : null,
      notEmptyRule: [(v) => (v && !!v.trim()) || 'Darf nicht leer sein'],
    };
  },

  computed: {
    oneOptionSelectedRule() {
      return [this.selectedAnswer != null || '!!'];
    },
  },

  watch: {
    selectedAnswer(val) {
      if (val) {
        this.$emit('updateAnswerOptions', this.questionIndex, val);
      }
    },
  },

  methods: {
    addNewAnswer(index) {
      this.$emit('addNewAnswer', index);
    },
    copyQuestion(index) {
      this.$emit('copyQuestion', index);
    },
    deleteQuestion(index, item) {
      this.$emit('deleteQuestion', index, item);
    },
    removeAnswer(questionIndex, answerOptionIndex) {
      this.$emit('removeAnswer', questionIndex, answerOptionIndex);
    },
    addQuestionImage(index) {
      this.$emit('addQuestionImage', index);
    },
    onFileChanged(e) {
      this.$emit('onFileChanged', e);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ak-question-radio-wrapper {
  height: 100%;
  .v-input__control {
    height: 100%;
  }
  .v-input__slot {
    margin: 0;
    height: 100% !important;
  }

  .v-input--radio-group__input {
    height: 100% !important;
    display: flex;
    justify-content: space-around;
  }
}
</style>
