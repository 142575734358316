<template>
  <v-container
    :fluid="$vuetify.breakpoint.smAndDown"
    class="home"
    :class="{ 'pt-10': $vuetify.breakpoint.smAndUp }"
  >
    <AKAccessDeniedMessage v-if="accessDenied" />
    <template v-else>
      <v-row justify="center" class="flex-row">
        <v-col cols="5">
          <p class="ak-section-title">Lerneinheiten</p>
          <v-btn
            width="100%"
            height="80"
            class="ak-open-unitlist"
            @click="goToUnitList()"
          >
            Lerneinheiten Pool
          </v-btn>
          <v-btn
            text
            color="#fff"
            class="pl-0 pr-1 ak-addnew-button"
            @click="addNewUnit()"
          >
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            Neue Lerneinheit
          </v-btn>
          <v-divider color="#fff" class="my-12" />

          <p class="ak-section-title">Spiele</p>
          <v-btn
            width="100%"
            height="80"
            class="ak-open-gamelist"
            @click="goToGamesList()"
          >
            Spiele Pool
          </v-btn>

          <v-btn
            text
            color="#fff"
            class="pl-0 pr-1 ak-addnew-button"
            @click="addNewGame()"
          >
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            Neues Spiel
          </v-btn>
        </v-col>
        <v-col cols="5">
          <p class="ak-section-title">Klassen</p>
          <v-btn
            width="100%"
            height="80"
            class="ak-open-unitlist"
            @click="goToClassList()"
          >
            Klassen Pool
          </v-btn>
          <v-btn
            text
            color="#fff"
            class="pl-0 pr-1 ak-addnew-button"
            @click="addNewClass()"
          >
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            Klasse hinzufügen
          </v-btn>
          <v-divider color="#fff" class="my-12" />

          <p class="ak-section-title">User Pool</p>
          <v-btn
            width="100%"
            height="80"
            class="ak-open-unitlist"
            @click="goToUserList()"
          >
            User Pool
          </v-btn>
          <v-btn
            text
            color="#fff"
            class="pl-0 pr-1 ak-addnew-button"
            @click="addNewUser()"
          >
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            Userprofil hinzufügen
          </v-btn>
          <v-btn
            text
            color="#fff"
            class="pl-1 pr-1 ak-addnew-button"
            @click="importUsers"
            :loading="isSelecting"
          >
            <div class="pl-6 pr-3">
              <mdicon class="ak-icon-import" name="Upload" size="20" />
            </div>
            User importieren
            <input
              id="uploader"
              class="d-none"
              ref="userFileUploader"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="uploadUsersFile($event)"
            />
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AKAccessDeniedMessage from '@/components/common/AKAccessDeniedMessage';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  name: 'Home',
  mixins: [CommonMixins],
  components: { AKAccessDeniedMessage },
  data() {
    return {
      games: [
        {
          id: '12',
          name: 'Game 1',
        },
        {
          id: '22',
          name: 'Game 33',
        },
        {
          id: '33',
          name: 'Game 65',
        },
      ],
      isSelecting: false,
    };
  },
  computed: {
    hasUserInfo() {
      return this.$store.state['user'].userInformation.isLoaded;
    },
    userInfo() {
      return this.hasUserInfo
        ? this.$store.state['user'].userInformation.data
        : null;
    },
  },
  created() {
    this.getLUList();
  },
  methods: {
    importUsers() {
      this.isSelecting = true;
      window.addEventListener('focus', () => {
        this.isSelecting = false;
      });
      let element = document.getElementById('uploader');
      if (element) {
        element.click();
      }
    },

    goToUserList() {
      this.$router
        .push({ name: 'UsersList', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
    goToGamesList() {
      this.$router
        .push({ name: 'GamesList', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
    goToUnitList() {
      this.$router
        .push({ name: 'LearningUnitList', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewUnit() {
      this.$router
        .push({ name: 'AddLearningUnit', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewUser() {
      this.$router
        .push({ name: 'AddUser', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
    goToClassList() {
      this.$router
        .push({ name: 'ClassesList', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewClass() {
      this.$router
        .push({ name: 'AddClass', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewGame() {
      this.$router
        .push({ name: 'AddGame', params: { from: 'Home' } })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-user {
  &-name {
    color: #fff !important;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  &-photo {
    border: 8px solid var(--v-secondary-base) !important;
    height: 119px;
    width: 119px;
  }
}

.ak-icon-import {
  background: var(--v-sandWhite-base);
  border-radius: 60px;
  display: flex;
  height: 29px;
  width: 29px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--v-primary-base);
}

.ak-section-title {
  font-family: 'SFProText';
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: var(--v-secondary-base);
  margin-bottom: 25px;
}

::v-deep .v-btn__content {
  text-transform: none !important;
  letter-spacing: 0px !important;
}

.ak-open {
  &-gamelist,
  &-unitlist {
    background: var(--v-secondary-base);
    box-shadow: 0 3px 0px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 22px 20px !important;
    margin-bottom: 43px;
    justify-content: flex-start;

    ::v-deep .v-btn__content {
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: var(--v-fontGrey-base);
    }
  }
}

.ak-addnew-button {
  ::v-deep .v-btn__content {
    font-family: 'SFProText';
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--v-sandWhite-base);
  }
}
</style>
