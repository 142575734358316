export const actions = {
  clearSnackData({ commit }) {
    commit('clearSnackData');
  },

  setSnackData({ commit }, snackInfo) {
    commit('setSnackData', { snackInfo });
  },

  showOverlay({ commit }) {
    commit('showOverlay');
  },

  hideOverlay({ commit }) {
    commit('hideOverlay');
  },
};
