<template>
  <v-container
    :fluid="$vuetify.breakpoint.smAndDown"
    class="ak-user-list pb-10"
    :class="{ 'pt-10': $vuetify.breakpoint.smAndUp }"
  >
    <AKAccessDeniedMessage v-if="accessDenied" />
    <template v-else>
      <v-row no-gutters>
        <v-col cols="12" class="mt-5 d-flex justify-start align-center">
          <AKButton
            @click="goBackHome()"
            icon="ArrowLeft"
            :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
            :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
            class="goBackBtn"
          />
          <h2 class="ak-colorText-white font-protext font30-600 mr-5">
            User pool
          </h2>
          <v-btn text color="#fff" class="px-0 pr-2" @click="addNewUser()">
            <mdicon class="ak-icon pr-3" name="PlusCircle" size="34" />
            User hinzufügen
          </v-btn>
          <v-btn
            text
            color="#fff"
            class="pl-5 px-0 pr-2"
            @click="importUsers"
            :loading="isSelecting"
          >
            <div class="pr-3">
              <mdicon class="ak-icon-import" name="Upload" size="20" />
            </div>
            User importieren
            <input
              id="uploader"
              class="d-none"
              ref="userFileUploader"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="uploadUsersFile($event)"
            />
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="#fff"
            v-model="userSearch"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            solo
            hide-details
            class="mt-4"
          />
          <div class="full-width ak-select-table">
            <p @click="showStudent($event)" class="">User Studenten</p>
            <span>|</span>
            <p @click="showManager($event)" class="">User Manager</p>
            <span>|</span>
            <p @click="showTeacher($event)" class="">User Teacher</p>
            <span>|</span>
            <p @click="showAll($event)" class="active">Alle</p>
          </div>
          <v-data-table
            :headers="headers"
            :items="usersList"
            item-key="email"
            class="elevation-0 my-5"
            :search="userSearch"
            :custom-filter="customSearch"
            :footer-props="{
              'items-per-page-text': 'Anzahl Ergebnisse',
              'items-per-page-options': [12, 24, 36, 48, -1],
            }"
            :options="optionsTable"
            @click:row="rowClick"
          >
            <template v-slot:item.groups="{ item }">
              <span v-for="(el, index) in item.groups" :key="index">
                {{ el.name
                }}<template v-if="index < item.groups.length - 1">, </template>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AKAccessDeniedMessage from '@/components/common/AKAccessDeniedMessage';
import AKButton from '@/components/common/core/AKButton';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  name: 'UsersList',
  components: { AKButton, AKAccessDeniedMessage },
  mixins: [CommonMixins],
  data() {
    return {
      userSearch: '',
      isSelecting: false,
    };
  },
  created() {
    this.getUsersList();
  },
  computed: {
    headers() {
      return [
        { text: 'Name User', value: 'name', sortable: false },
        { text: 'Nickname', value: 'nickname', sortable: false },
        { text: 'Klasse', value: 'groups', sortable: false },
      ];
    },
  },
  methods: {
    importUsers() {
      this.isSelecting = true;
      window.addEventListener('focus', () => {
        this.isSelecting = false;
      });
      let element = document.getElementById('uploader');
      if (element) {
        element.click();
      }
    },

    setActiveClass(event) {
      Array.from(document.querySelectorAll('.ak-select-table p')).forEach(
        (el) => el.classList.remove('active')
      );
      event.currentTarget.className += ' active';
    },

    showStudent(event) {
      this.usersList = [];
      let val = ['STUDENT'];
      this.getUsersList(val);
      this.setActiveClass(event);
    },

    showManager(event) {
      this.usersList = [];
      let val = ['MANAGER'];
      this.getUsersList(val);
      this.setActiveClass(event);
    },

    showTeacher(event) {
      this.usersList = [];
      let val = ['TEACHER'];
      this.getUsersList(val);
      this.setActiveClass(event);
    },

    showAll(event) {
      this.usersList = [];
      this.getUsersList();
      this.setActiveClass(event);
    },

    rowClick(val) {
      this.goTo(val.id);
    },

    customSearch(value, userSearch, item) {
      if (userSearch === null) {
        return false;
      }

      let tokens = userSearch.split(' ');

      let match = true;
      for (let i = 0; i < tokens.length && match === true; i++) {
        let token = tokens[i].toString().toLowerCase();
        match =
          match &&
          (item.firstName.toString().toLowerCase().indexOf(token) !== -1 ||
            item.lastName.toString().toLowerCase().indexOf(token) !== -1 ||
            item.nickname.toString().toLowerCase().indexOf(token) !== -1 ||
            item.groups.some(
              (it) => it.name.toString().toLowerCase().indexOf(token) !== -1
            ));
      }
      return match;
    },

    goTo(val) {
      if (val !== null) {
        this.$router
          .push({
            name: 'AddUser',
            params: { userId: val, from: 'UsersList' },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    addNewUser() {
      this.$router
        .push({ name: 'AddUser', params: { from: 'UsersList' } })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-user-list {
  position: relative;
  height: 100%;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 60vw;
  }
}
.ak-section-title {
  font-family: 'SFProText';
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #f1f0ec;
  margin-bottom: 25px;
  text-align: center;
}

.ak-icon-import {
  background: #ffffff;
  border-radius: 60px;
  display: flex;
  height: 29px;
  width: 29px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
}

::v-deep .v-btn__content {
  text-transform: none !important;
  letter-spacing: 0px !important;
}

::v-deep .ak-select-table {
  margin: 20px 0;
  color: #fff;
  display: flex;
  justify-content: flex-start;

  p {
    color: #fff;
    margin: 0;
    cursor: pointer;

    &.active {
      font-weight: bold;
      border-bottom: 2px solid #fff;
    }
  }

  span {
    margin: 0 10px;
  }
}
</style>
