export const mutations = {
  gameInformationLoaded(state, { gameInformation }) {
    state.gameInformation.data = {
      ...gameInformation,
    };
    state.gameInformation.isLoaded = true;
  },

  clearCurrentGame(state) {
    state.currentGame = {
      value: [],
    };
  },

  clearGameInformation(state) {
    state.gameInformation.data = null;
    state.gameInformation.isLoaded = false;
  },

  clearGameQuestion(state) {
    state.questionInformation.data = null;
    state.questionInformation.isLoaded = false;
  },

  questionInformationLoaded(state, { question }) {
    state.questionInformation.data = question;
    state.currentGame.value.question = question;
    if (question !== null) {
      state.questionInformation.isLoaded = true;
    } else {
      state.questionInformation.isLoaded = false;
    }
  },

  loadCurrentGame(state, { currentGame }) {
    state.currentGame = {
      value: currentGame,
    };
    state.gameInformation.isLoaded = true;
  },
};
