<template>
  <v-container fluid class="header-navigation">
    <v-row no-gutters>
      <v-col cols="8"> </v-col>
      <v-col cols="4" class="d-flex justify-end align-center ak-user-info">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="100"
          offset-x
          z-index="10"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="ak-user-ranks align-center" v-bind="attrs" v-on="on">
              <span class="ak-user-name">{{ name }}</span>
              <AKAvatar
                :hasUserInfo="hasUserInfo"
                :avatar="avatar"
                :name="name"
              />
            </div>
          </template>

          <v-card>
            <v-list>
              <v-list-item class="py-2 justify-center">
                <AKAvatar
                  :hasUserInfo="hasUserInfo"
                  :avatar="avatar"
                  :name="name"
                />
              </v-list-item>
              <v-divider />
              <v-list-item>
                <a href="/oauth/logout" class="ak-link">Logout</a>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AKAvatar from '@/components/common/core/AKAvatar';
export default {
  name: 'HeaderNavigation',
  components: { AKAvatar },
  data() {
    return {
      menu: false,
    };
  },
  props: {
    hasUserInfo: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-navigation {
  background-color: var(--v-secondary-base);
  display: flex;
}

.ak-user {
  &-name {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-right: 10px;
  }
}
</style>
